import { i18n } from "@/utils/i18n";
import { generatePageTitle } from "@/helpers/pageTitle";

const routes = [
  {
    path: "/contacts",
    component: () => import("@/views/app/pages/contacts/dashboard")
  },

  // Customer
  {
    name: "ContactCustomerListPage",
    path: "/contacts/customer",
    meta: { page: "customer", listPage: "contacts", title: generatePageTitle(i18n.t("menu.customers")) },
    component: () => import("@/views/app/pages/contacts/list/customer")
  },
  {
    name: "ContactCustomerNewPage",
    path: "/contacts/customer/new",
    meta: {
      page: "customer",
      title: generatePageTitle(i18n.t("general.new_suffix", { suffix: i18n.t("menu.customer") }))
    },
    component: () => import("@/views/app/pages/contacts/new")
  },

  {
    // With Custom Fields
    path: "/contacts/customer/new/:custom_field_group_id",
    meta: {
      page: "customer",
      title: generatePageTitle(i18n.t("general.new_suffix", { suffix: i18n.t("menu.customer") }))
    },
    component: () => import("@/views/app/pages/contacts/new")
  },
  {
    name: "ContactCustomerUpdatePage",
    path: "/contacts/customer/:id",
    meta: { page: "customer", permissionKeyPostfix: "customers" },
    component: () => import("@/views/app/pages/contacts/update")
  },

  // Supplier
  {
    name: "ContactSupplierListPage",
    path: "/contacts/supplier",
    meta: { page: "supplier", listPage: "contacts", title: generatePageTitle(i18n.t("menu.suppliers")) },
    component: () => import("@/views/app/pages/contacts/list/supplier")
  },
  {
    name: "ContactSupplierListPage",
    path: "/contacts/supplier/new",
    meta: {
      page: "supplier",
      title: generatePageTitle(i18n.t("general.new_suffix", { suffix: i18n.t("menu.supplier") }))
    },
    component: () => import("@/views/app/pages/contacts/new")
  },

  // With Custom Fields
  {
    path: "/contacts/supplier/new/:custom_field_group_id",
    meta: {
      page: "supplier",
      title: generatePageTitle(i18n.t("general.new_suffix", { suffix: i18n.t("menu.supplier") }))
    },
    component: () => import("@/views/app/pages/contacts/new")
  },
  {
    name: "ContactSupplierUpdatePage",
    path: "/contacts/supplier/:id",
    meta: { page: "supplier", permissionKeyPostfix: "suppliers" },
    component: () => import("@/views/app/pages/contacts/update")
  }
];

export default routes;
