import { i18n } from "@/utils/i18n";
import { generatePageTitle } from "@/helpers/pageTitle";

const routes = [
  // Price Types
  {
    path: "/inventory/price/types",
    name: "PriceTypeListPage",
    meta: {
      page: "price_types",
      listPage: "inventory_price_types",
      listPageTitleKey: "price_types",
      title: generatePageTitle(i18n.t("menu.price_types"))
    },
    component: () => import("@/views/app/pages/inventory/price/types/list")
  },
  {
    name: "NewPriceTypePage",
    meta: {
      title: generatePageTitle(i18n.t("general.new_suffix", { suffix: i18n.t("menu.price_type") }))
    },
    path: "/inventory/price/types/new",
    component: () => import("@/views/app/pages/inventory/price/types/new")
  },
  {
    name: "PriceTypeUpdatePage",
    path: "/inventory/price/types/:id",
    component: () => import("@/views/app/pages/inventory/price/types/update")
  },

  //Product Var Option Types
  {
    path: "/inventory/product/var/option/types",
    meta: {
      page: "product_var_options",
      listPage: "inventory_product_option_var_types",
      listPageTitleKey: "product_var_options"
    },
    component: () => import("@/views/app/pages/inventory/product/var/option/types/list")
  },
  {
    path: "/inventory/product/var/option/types/new",
    component: () => import("@/views/app/pages/inventory/product/var/option/types/new")
  },
  {
    path: "/inventory/product/var/option/types/:id",
    component: () => import("@/views/app/pages/inventory/product/var/option/types/update")
  },

  // Product Types
  {
    name: "InventoryProductTypeListPage",
    path: "/inventory/product/types",
    meta: {
      page: "product_types",
      listPage: "inventory_product_types",
      listPageTitleKey: "product_types",
      title: generatePageTitle(i18n.t("menu.product_types"))
    },
    component: () => import("@/views/app/pages/inventory/product/types/list")
  },
  {
    name: "InventoryProductTypeNewPage",
    path: "/inventory/product/types/new",
    meta: {
      title: generatePageTitle(i18n.t("general.new_suffix", { suffix: i18n.t("menu.product_type") }))
    },
    component: () => import("@/views/app/pages/inventory/product/types/new")
  },
  {
    name: "InventoryProductTypeUpdatePage",
    path: "/inventory/product/types/:id",
    component: () => import("@/views/app/pages/inventory/product/types/update")
  },

  // Inventory Product
  {
    name: "InventoryProductListPage",
    path: "/inventory/products",
    meta: {
      page: "products",
      listPage: "inventory_products",
      listPageTitleKey: "products",
      title: generatePageTitle(i18n.t("menu.products"))
    },
    component: () => import("@/views/app/pages/inventory/products/list")
  },
  {
    name: "InventoryProductOrderRequestsListPage",
    path: "/inventory/products/order_requests",
    meta: {
      page: "products",
      listPage: "inventory_products",
      listPageTitleKey: "order_requests",
      title: generatePageTitle(i18n.t("menu.order_requests"))
    },
    component: () => import("@/views/app/pages/inventory/products/list/OrderRequests")
  },
  {
    name: "ProductsNewPage",
    path: "/inventory/products/new",
    meta: {
      title: generatePageTitle(i18n.t("general.new_suffix", { suffix: i18n.t("menu.product") }))
    },
    component: () => import("@/views/app/pages/inventory/products/new")
  },
  {
    name: "ProductUpdatePage",
    path: "/inventory/products/:id",
    component: () => import("@/views/app/pages/inventory/products/update")
  },
  // New Variation
  {
    name: "VariationNewPage",
    path: "/inventory/products/:id/variations",
    meta : {
      title: generatePageTitle(i18n.t("general.new_suffix", { suffix: i18n.t("menu.variation") }))
    },
    component: () => import("@/views/app/pages/inventory/products/variations")
  },
  // Edit Variation
  {
    name: "VariationUpdatePage",
    path: "/inventory/products/:id/variations/:variation_id",
    component: () => import("@/views/app/pages/inventory/products/variations")
  },
  // New/Edit Karavan
  {
    name: "KaravanUpdatePage",
    path: "/inventory/products/:id/karavan/:karavan_id",
    component: () => import("@/views/app/pages/inventory/products/karavan")
  },

  // ******************** Product RePacks ***************************
  // Inventory Product Re-Packs
  {
    name: "InventoryProductRePackListPage",
    path: "/inventory/product/re_packs",
    meta: {
      listPage: "product_re_packs",
      listPageTitleKey: "product_re_packs"
    },
    component: () => import("@/views/app/pages/inventory/product/re_packs/list")
  },
  {
    name: "InventoryProductRePackNewPage",
    path: "/inventory/product/re_packs/new",
    component: () => import("@/views/app/pages/inventory/product/re_packs/new")
  },
  {
    name: "InventoryProductRePackUpdatePage",
    path: "/inventory/product/re_packs/:id",
    component: () => import("@/views/app/pages/inventory/product/re_packs/update")
  },
  // {
  //   path: "/inventory/product/re_packs/:id/products",
  //   component: () => import("@/views/app/pages/inventory/product/re_packs/products")
  // }

  // ********************** PRODUCT CATEGORY ******************************
  // Inventory Product Re-Packs
  {
    name: "InventoryProductCategoryListPage",
    path: "/inventory/product/categories",
    meta: {
      listPage: "inventory_categories",
      listPageTitleKey: "product_categories",
      title : generatePageTitle(i18n.t("menu.product_categories"))
    },
    component: () => import("@/views/app/pages/inventory/product/categories/list")
  },
  {
    path: "/inventory/product/categories/sub_categories/:parent_category",
    meta: {
      listPage: "inventory_categories",
      listPageTitleKey: "product_categories",
      title : generatePageTitle(i18n.t("menu.product_categories"))
    },
    component: () => import("@/views/app/pages/inventory/product/categories/sub_category_list")
  },
  {
    name : "ProductCategoryNewPage",
    path: "/inventory/product/categories/new",
    meta : {
      title: generatePageTitle(i18n.t("general.new_suffix", { suffix: i18n.t("menu.product_category") }))
    },
    component: () => import("@/views/app/pages/inventory/product/categories/new")
  },
  {
    path: "/inventory/product/categories/:id",
    component: () => import("@/views/app/pages/inventory/product/categories/update")
  }
];

export default routes;
