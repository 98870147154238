import { i18n } from "@/utils/i18n";
import { generatePageTitle } from "@/helpers/pageTitle";

const routes = [
  // ************************* Accounting Payments ****************************/
  {
    path: "/accounting/",
    name: 'AccountingDashboard',
    component: () => import("@/views/app/pages/accounting/dashboard")
  },

  {
    path: "/accounting/invoices",
    component: () => import("@/views/app/pages/accounting/dashboard")
  },

  // {
  //   path: "/accounting/invoices/manage/:id",
  //   component: () => import("@/views/app/pages/accounting/invoices/manage")
  // },

  {
    path: "/accounting/invoices/purchases",
    component: () => import("@/views/app/pages/accounting/dashboard")
  },
  {
    path: "/accounting/invoices/sales",
    component: () => import("@/views/app/pages/accounting/dashboard")
  },
  {
    path: "/accounting/invoices/quotes",
    component: () => import("@/views/app/pages/accounting/dashboard")
  },
  {
    path: "/accounting/payments",
    component: () => import("@/views/app/pages/accounting/dashboard")
  },

  {
    name: "AccountingPaymentsSentListPage",
    path: "/accounting/payments/sent",
    meta: {
      page: "payments",
      listPage: "accounting_payments",
      listPageTitleKey: "payments",
      type: "sent",
      title : generatePageTitle(`${i18n.t("menu.payments")} | ${i18n.t("menu.sent")}`)
    },
    component: () => import("@/views/app/pages/accounting/payments/list/sent")
  },
  {
    name: "AccountingPaymentsReceivedListPage",
    path: "/accounting/payments/received",
    meta: {
      page: "payments",
      listPage: "accounting_payments",
      listPageTitleKey: "payments",
      type: "received",
      title : generatePageTitle(`${i18n.t("menu.payments")} | ${i18n.t("menu.received")}`)

    },
    component: () => import("@/views/app/pages/accounting/payments/list/received")
  },
  {
    path: "/accounting/payments/sent/new",
    name: "AccountingPaymentsSentNewPage",
    component: () => import("@/views/app/pages/accounting/payments/new"),
    meta : {
      title : generatePageTitle(i18n.t("pages.accounting.payments.forms.title-new"))
    }
  },
  {
    path: "/accounting/payments/received/new",
    component: () => import("@/views/app/pages/accounting/payments/new"),
    name: 'AccountingPaymentsReceivedNewPage',
    meta : {
      title : generatePageTitle(i18n.t("pages.accounting.payments.forms.title-new"))
    }
  },
  {
    path: "/accounting/payments/:id",
    name: 'AccountingPaymentsUpdatePage',
    component: () => import("@/views/app/pages/accounting/payments/update")
  },

  // ************************* /Accounting Payments ***************************************/

  // ****************************** PURCHASE *********************************************************
  // purchase order /accounting/invoices/purchase/proforma
  // **** Listing Pages ****
  {
    name: "AccountingInvoicePurchaseOrderListPage",
    path: "/accounting/invoices/purchases/purchase_order",
    meta: {
      page: "invoices",
      listPage: "invoices",
      listPageTitleKey: "purchase_order",
      trade_type: "purchase",
      type: "proforma",
      permissionModule: "accounting",
      permissionSubModule: "accounting_invoice_proforma_purchase",
      title: generatePageTitle(i18n.t("menu.purchase_order"))
    },
    component: () => import("@/views/app/pages/accounting/invoices/list/purchases/po")
  },
  // bill /accounting/invoices/purchase/final
  {
    name: "AccountingInvoiceBillListPage",
    path: "/accounting/invoices/purchases/bill",
    meta: {
      page: "invoices",
      listPage: "invoices",
      listPageTitleKey: "bill",
      trade_type: "purchase",
      type: "final",
      permissionModule: "accounting",
      permissionSubModule: "accounting_invoice_final_purchase",
      title: generatePageTitle(i18n.t("menu.bill"))
    },
    component: () => import("@/views/app/pages/accounting/invoices/list/purchases/bill")
  },

  // **** New Pages for PO ****
  {
    name: "AccountingInvoicePurchaseOrderNewPage",
    path: "/accounting/invoices/purchases/purchase_order/new",
    meta: {
      trade_type: "purchase",
      type: "proforma",
      page_title: "purchase_order",
      title: generatePageTitle(i18n.t("general.new_suffix", { suffix: i18n.t("menu.purchase_order") })),
      listPageName: 'AccountingInvoicePurchaseOrderListPage'
    },
    component: () => import("@/views/app/pages/accounting/invoices/new")
  },
  {
    path: "/accounting/invoices/purchases/purchase_order/:hive_project_id/new",
    meta: {
      trade_type: "purchase",
      type: "proforma",
      module_key: "accounting_invoice_proforma_purchase",
      page_title: "purchase_order",
      title: generatePageTitle(i18n.t("general.new_suffix", { suffix: i18n.t("menu.purchase_order") })),
      listPageName: 'AccountingInvoicePurchaseOrderListPage'
    },
    component: () => import("@/views/app/pages/accounting/invoices/new")
  },
  {
    path: "/accounting/invoices/purchases/purchase_order/new/:custom_field_group_id",
    name: "AccountingInvoicePurchaseOrderCFNewPage",
    meta: {
      trade_type: "purchase",
      type: "proforma",
      module_key: "accounting_invoice_proforma_purchase",
      page_title: "purchase_order",
      title: generatePageTitle(i18n.t("general.new_suffix", { suffix: i18n.t("menu.purchase_order") })),
      listPageName: 'AccountingInvoicePurchaseOrderListPage'
    },
    component: () => import("@/views/app/pages/accounting/invoices/new")
  },
  // **** New Pages for Bill ****
  {
    name: "AccountingInvoicePurchaseBillNewPage",
    path: "/accounting/invoices/purchases/bill/new",
    meta: {
      trade_type: "purchase",
      type: "final",
      module_key: "accounting_invoice_final_purchase",
      page_title: "bill",
      title: generatePageTitle(i18n.t("general.new_suffix", { suffix: i18n.t("menu.bill") })),
      listPageName: 'AccountingInvoiceBillListPage'
    },
    component: () => import("@/views/app/pages/accounting/invoices/new")
  },
  {
    path: "/accounting/invoices/purchases/bill/new/:custom_field_group_id",
    name: "AccountingInvoicePurchaseBillCFNewPage",
    meta: {
      trade_type: "purchase",
      type: "final",
      module_key: "accounting_invoice_final_purchase",
      page_title: "bill",
      title: generatePageTitle(i18n.t("general.new_suffix", { suffix: i18n.t("menu.bill") })),
      listPageName: 'AccountingInvoiceBillListPage'
    },
    component: () => import("@/views/app/pages/accounting/invoices/new")
  },

  // ****************************** SALE *********************************************************
  // proforma invoice /accounting/invoices/sale/proforma
  // **** Listing Pages ****
  {
    name: "AccountingQuotesListPage",
    path: "/accounting/invoices/sales/quote",
    meta: {
      page: "invoices",
      listPage: "invoices",
      listPageTitleKey: "quote",
      trade_type: "sale",
      type: "quote",
      permissionModule: "accounting",
      permissionSubModule: "accounting_invoice_quote_sale",
      title: generatePageTitle(i18n.t("menu.quotes"))
    },
    component: () => import("@/views/app/pages/accounting/invoices/list/sales/quote")
  },
  {
    name: "AccountingInvoiceProformaInvoiceListPage",
    path: "/accounting/invoices/sales/proforma_invoice",
    meta: {
      page: "invoices",
      listPage: "invoices",
      listPageTitleKey: "proforma_invoice",
      trade_type: "sale",
      type: "proforma",
      permissionModule: "accounting",
      permissionSubModule: "accounting_invoice_proforma_sale",
      title: generatePageTitle(i18n.t("menu.proforma_invoices"))
    },
    component: () => import("@/views/app/pages/accounting/invoices/list/sales/pi")
  },
  // invoice /accounting/invoices/sale/final
  {
    name: "AccountingInvoiceFinalInvoiceListPage",
    path: "/accounting/invoices/sales/invoice",
    meta: {
      page: "invoices",
      listPage: "invoices",
      listPageTitleKey: "invoice",
      trade_type: "sale",
      type: "final",
      permissionModule: "accounting",
      permissionSubModule: "accounting_invoice_final_sale",
      title: generatePageTitle(i18n.t("menu.invoices"))
    },
    component: () => import("@/views/app/pages/accounting/invoices/list/sales/invoice")
  },

  // **** New Pages for PI ****
  {
    path: "/accounting/invoices/sales/quote/new",
    name: "AccountingInvoiceQuoteNewPage",
    meta: {
      trade_type: "sale",
      type: "quote",
      module_key: "accounting_invoice_quote_sale",
      page_title: "quote",
      title: generatePageTitle(i18n.t("general.new_suffix", { suffix: i18n.t("menu.quote") })),
      listPageName: 'AccountingQuotesListPage'
    },
    component: () => import("@/views/app/pages/accounting/invoices/new")
  },
  {
    path: "/accounting/invoices/sales/proforma_invoice/new",
    name: 'AccountingInvoiceProformaInvoiceNewPage',
    meta: {
      trade_type: "sale",
      type: "proforma",
      module_key: "accounting_invoice_proforma_sale",
      page_title: "proforma_invoice",
      title: generatePageTitle(i18n.t("general.new_suffix", { suffix: i18n.t("menu.proforma_invoice") })),
      listPageName: 'AccountingInvoiceProformaInvoiceListPage'
    },
    component: () => import("@/views/app/pages/accounting/invoices/new")
  },
  {
    path: "/accounting/invoices/sales/proforma_invoice/new/:custom_field_group_id",
    name: 'AccountingInvoiceProformaInvoiceCFNewPage',
    meta: {
      trade_type: "sale",
      type: "proforma",
      module_key: "accounting_invoice_proforma_sale",
      page_title: "proforma_invoice",
      title:generatePageTitle( i18n.t("general.new_suffix", { suffix: i18n.t("menu.proforma_invoice") })),
      listPageName: 'AccountingInvoiceProformaInvoiceListPage'

    },
    component: () => import("@/views/app/pages/accounting/invoices/new")
  },
  // **** New Pages for Invoice ****
  {
    path: "/accounting/invoices/sales/invoice/new",
    name: 'AccountingInvoicesFinalInvoiceNewPage',
    meta: {
      trade_type: "sale",
      type: "final",
      module_key: "accounting_invoice_final_sale",
      page_title: "invoice",
      title: generatePageTitle(i18n.t("general.new_suffix", { suffix: i18n.t("menu.invoice") })),
      listPageName: 'AccountingInvoiceFinalInvoiceListPage'
    },
    component: () => import("@/views/app/pages/accounting/invoices/new")
  },
  {
    path: "/accounting/invoices/sales/invoice/new/:custom_field_group_id",
    name: 'AccountingInvoicesFinalInvoiceCFNewPage',
    meta: {
      trade_type: "sale",
      type: "final",
      module_key: "accounting_invoice_final_sale",
      page_title: "invoice",
      title: generatePageTitle(i18n.t("general.new_suffix", { suffix: i18n.t("menu.invoice") })),
      listPageName: 'AccountingInvoiceFinalInvoiceListPage'
    },
    component: () => import("@/views/app/pages/accounting/invoices/new")
  },

  {
    name: "QuickOrderPage",
    // path: "/inventory/products/quick_order",
    path: "/accounting/invoices/sales/quick_order",
    meta: {
      page: "quick_order",
      listPage: "inventory_products",
      listPageTitleKey: "quick_order",
      title: generatePageTitle(i18n.t("menu.quick_order"))
    },
    component: () => import("@/views/app/pages/accounting/invoices/list/sales/OrderPage")
  },
  {
    name: "OrderBuilderPage",
    path: "/accounting/invoices/sales/order-builder",
    meta: {
      page: "order_builder",
      listPage: "inventory_products",
      listPageTitleKey: "order_builder",
      title: generatePageTitle(i18n.t("menu.order_builder"))
    },
    component: () => import("@/views/app/pages/accounting/invoices/list/sales/OrderBuilder")
  },

  // ***********************  UPDATE Page And Invoice Items ******************/
  // **** Update Page ****
  {
    name: "AccountingInvoicesUpdatePage",
    path: "/accounting/invoices/:id",
    component: () => import("@/views/app/pages/accounting/invoices/update")
  },

  // ****************** Commissions ***********************************/
  {
    path: "/accounting/commissions",
    name: 'AccountingCommissionsListPage',
    meta: {
      page: "commissions",
      title: generatePageTitle(i18n.t("menu.commissions"))
    },
    component: () => import("@/views/app/pages/accounting/commissions/list")
  },
  // for User Route
  {
    path: "/accounting/commissions/:belonged_object/:contact_id",
    component: () => import("@/views/app/pages/accounting/commissions")
  },
  // For Contact Route..
  {
    path: "/accounting/commissions/:belonged_object/:contact_type/:contact_id",
    component: () => import("@/views/app/pages/accounting/commissions")
  },

  {
    name: "AccountingCommissionRulesListPage",
    meta: {
      listPageTitleKey: "commission_rules",
      title: generatePageTitle(i18n.t("menu.commission_rules"))
    },
    path: "/accounting/commission_rules",
    component: () => import("@/views/app/pages/accounting/commission_rules/list")
  },
  {
    name: "AccountingCommissionRulesNewPage",
    path: "/accounting/commission_rules/new",
    component: () => import("@/views/app/pages/accounting/commission_rules/new")
  },
  {
    name: "AccountingCommissionRulesUpdatePage",
    path: "/accounting/commission_rules/:id",
    component: () => import("@/views/app/pages/accounting/commission_rules/update")
  },

  // **************** Taxes *******************************/
  {
    name: "AccountingTaxesListPage",
    path: "/accounting/taxes",
    meta: {
      title: generatePageTitle(i18n.t("menu.accounting_taxes"))
    },
    component: () => import("@/views/app/pages/accounting/taxes/list")
  },

  {
    path: "/accounting/taxes/new",
    component: () => import("@/views/app/pages/accounting/taxes/new")
  },

  {
    path: "/accounting/taxes/:id",
    component: () => import("@/views/app/pages/accounting/taxes/update")
  },

  // **************** Credits *********************************/
  {
    name: "CreditPage",
    path: "/accounting/credits",
    meta: {
      page: "credits",
      title: generatePageTitle(i18n.t("menu.credits"))
    },
    component: () => import("@/views/app/pages/accounting/credits/list")
  }
];

export default routes;
