<template>
  <b-form-group :tooltip="true" :label-for="`cf_${id}`" class="align-left">
    <slot v-if="!hideName" name="label">
      <div class="mb-2">
        <!-- {{ `cf_${id}` }} -->
        <template v-if="input_type !== 'checkbox'">
          {{ name }}
          <i v-if="descriptionAvailable" :id="'description_' + name" class="simple-icon-bulb text-primary"></i>
          <b-tooltip
            custom-class="custom_field_tooltip"
            delay="200"
            triggers="hover"
            :id="`tooltip_description_${name}`"
            v-if="descriptionAvailable"
            :target="'description_' + name"
            placement="right"
            @show="tooltipShowEvent"
          >
            <div v-html="tooltip_description"></div>
          </b-tooltip>
        </template>
      </div>
    </slot>
    <!-- input : text -->
    <b-form-input
      autocomplete="off"
      :disabled="disabled"
      @keyup="$emit('keyup', $event)"
      @keypress="$emit('keypress', $event)"
      @focusout="$emit('focusout', $event)"
      type="text"
      v-if="input_type === 'textbox'"
      :placeholder="placeholder"
      :value="default_value"
      v-model="input_textbox"
      :class="{ 'is-invalid': $v.input_textbox[errorPropertyDecisionMaker] }"
    />
    <div class="invalid-feedback" v-if="!$v.input_textbox.required && input_type == 'textbox'">
      {{ $t("form-errors.required") }}
    </div>
    <div class="invalid-feedback"
         v-if="!$v.input_textbox.email && input_type == 'textbox' && input_validator == 'email'">
      {{ $t("form-errors.invalid_email") }}
    </div>
    <div class="invalid-feedback"
         v-if="!$v.input_textbox.integer && input_type == 'textbox' && input_validator == 'integer'">
      {{ $t("form-errors.integer") }}
    </div>
    <div class="invalid-feedback"
         v-if="!$v.input_textbox.floatValidation && input_type == 'textbox' && input_validator == 'number'">
      {{ $t("form-errors.decimal") }}
    </div>

    <!-- input : checkbox -->
    <template v-if="checkboxFieldAvailable">
      <b-form-checkbox
        :disabled="disabled"
        :id="`cf_${id}`"
        v-if="checkboxFieldAvailable"
        @change="$v.input_checkbox.$touch()"
        v-model="input_checkbox"
      >
        <span>{{ name }}</span>
        <i v-if="descriptionAvailable" v-b-tooltip:hover.right.html="description"
           class="simple-icon-bulb text-primary"></i>
      </b-form-checkbox>
    </template>

    <template v-else-if="switchFieldAvailable">
      <label class="d-inline-block">
        <div class="d-flex justify-content-start align-items-center">
          <switches
            :disabled="disabled"
            @change="$v.input_checkbox.$touch()"
            theme="custom"
            :key="input_checkbox"
            v-model="input_checkbox"
            color="primary"
            class="mt-1 mr-2"
          ></switches>
          <span>{{ name }}</span>
          <i v-if="descriptionAvailable" v-b-tooltip:hover.right.html="description"
             class="ml-1 simple-icon-bulb text-primary"></i>
        </div>
      </label>
    </template>

    <div class="invalid-feedback" v-if="$v.input_checkbox.required && input_type == 'checkbox'">
      {{ $t("form-errors.required") }}
    </div>

    <!-- input : radio_button -->
    <b-form-radio
      :disabled="disabled"
      v-if="input_type == 'radio_button'"
      v-for="value in values"
      :key="value"
      :value="value"
      v-model="input_radio_button"
    >{{ value }}
    </b-form-radio
    >
    <div class="invalid-feedback" v-if="!$v.input_radio_button.required && input_type == 'radio_button'">
      {{ $t("form-errors.required") }}
    </div>

    <!-- input : textarea -->
    <b-form-textarea
      :disabled="disabled"
      v-if="input_type == 'text'"
      :placeholder="placeholder"
      rows="3"
      max-rows="6"
      v-model="input_text"
      :class="{ 'is-invalid': $v.input_text[errorPropertyDecisionMaker] }"
    ></b-form-textarea>
    <div class="invalid-feedback" v-if="!$v.input_text.required && input_type == 'text'">{{ $t("form-errors.required")
      }}
    </div>

    <!-- input : calendar -->
    <p-date-picker
      :disabled="disabled"
      v-if="input_type === 'calendar'"
      :class="{ 'is-invalid-custom': $v.input_calendar[errorPropertyDecisionMaker] }"
      v-model="input_calendar"
    />
    <div class="invalid-feedback-custom"
         v-if="$v.input_calendar[errorPropertyDecisionMaker] && input_type == 'calendar'">
      {{ $t("form-errors.required") }}
    </div>

    <!-- input : dropdownlist -->
    <v-select
      transition="none"
      :disabled="disabled"
      class="sm"
      :class="{ 'is-invalid-custom': $v.input_dropdownlist[errorPropertyDecisionMaker] }"
      v-if="input_type == 'dropdownlist'"
      label="name"
      v-model="input_dropdownlist"
      :options="values"
    />
    <div class="invalid-feedback" v-if="!$v.input_dropdownlist.required && input_type == 'dropdownlist'">
      {{ $t("form-errors.required") }}
    </div>

    <!-- input : smart ddl -->
    <v-select
      transition="none"
      :disabled="disabled"
      :class="smartDDLClasses"
      v-if="input_type === 'smart_ddl' && ['dropdownlist', 'multi_select'].indexOf(smart_ddl_type) > -1"
      label="name"
      v-model="input_smart_ddl"
      :options="smart_ddl_items"
      :placeholder="place_holder"
      :taggable="smart_ddl_type === 'multi_select'"
      :multiple="smart_ddl_type === 'multi_select'"
    />

    <treeselect
      v-if="input_type == 'smart_ddl' && smart_ddl_type === 'multi_select_checkbox'"
      v-model="input_smart_ddl"
      :isDisabled="disabled"
      :multiple="true"
      valueFormat="object"
      :placeholder="place_holder"
      :class="smartDDLClasses"
      :options="smartDDLItems"
    />

    <div class="invalid-feedback-custom" v-if="$v.input_smart_ddl.$error && input_type == 'smart_ddl'">
      {{ $t("form-errors.required") }}
    </div>

    <!-- input : inventory product -->
    <!-- Dropdown List -->
    <v-select
      transition="none"
      :disabled="disabled"
      v-if="inventory_product_options_loaded && input_type == 'inventory_product' && type_inventory_product == 'dropdownlist'"
      label="label"
      v-model="input_inventory_product"
      :options="product_options"
      :placeholder="place_holder"
      @search:blur="$emit('focusout', $event)"
      class="sm"
      :class="{ 'is-invalid-custom': $v.input_inventory_product[errorPropertyDecisionMaker] }"
    />
    <!-- Search -->
    <v-select
      transition="none"
      :disabled="disabled"
      v-if="input_type === 'inventory_product' && type_inventory_product === 'search'"
      label="name"
      :filterable="false"
      v-model="input_inventory_product"
      :options="product_options"
      :placeholder="place_holder"
      @input="setInventoryProduct"
      @search="onSearch"
      @search:blur="clearSearchBox"
      class="sm"
      :class="{ 'is-invalid-custom': $v.input_inventory_product[errorPropertyDecisionMaker] }"
    >
      <template slot="no-options" v-if="!didSearch">{{ $t("general.search_message.product") }}</template>
      <template slot="no-options" v-if="didSearch">{{ $t("general.search_message.no_options") }}</template>

      <template slot="option" slot-scope="option">
        <div class="d-flex justify-content-start align-items-center">
          <span :class="{ 'text-danger' : option.quantity === 0}" class="font-weight-bold mr-2"> {{ option.auto_id }} </span>
          <span>{{ option.name }}  </span>
        </div>
      </template>
      <template slot="selected-option" slot-scope="option">
        <div class="d-flex justify-content-start align-items-center">
          <span :class="{ 'text-danger' : option.quantity === 0}" class="font-weight-bold mr-2"> {{ option.auto_id }} </span>
          <span>{{ option.name }}</span>
        </div>
      </template>
    </v-select>
    <div class="invalid-feedback-custom"
         v-if="$v.input_inventory_product[errorPropertyDecisionMaker] && input_type == 'inventory_product'">
      {{ $t("form-errors.required") }}
    </div>
    <!-- input : inventory product -->

    <!-- input : contact_customer -->
    <contact-search
      v-if="input_type === 'contact_customer'"
      class="sm"
      v-model="input_contact_customer"
      contact_type="customer"
      :placeholder="place_holder"
      :return_fields="return_fields"
      :label="false"
      :new-design="true"
      :class="{ 'is-invalid-custom': $v.input_contact_customer[errorPropertyDecisionMaker] }"
    />
    <div class="invalid-feedback-custom"
         v-if="$v.input_contact_customer[errorPropertyDecisionMaker] && input_type == 'contact_customer'">
      {{ $t("form-errors.required") }}
    </div>
    <!-- !input : contact_customer -->

    <!-- input : contact_supplier -->
    <contact-search
      v-if="input_type === 'contact_supplier'"
      class="sm"
      v-model="input_contact_supplier"
      contact_type="supplier"
      :placeholder="place_holder"
      :return_fields="return_fields"
      :label="false"
      :new-design="true"
      :class="{ 'is-invalid-custom': $v.input_contact_supplier[errorPropertyDecisionMaker] }"
    />
    <div class="invalid-feedback-custom"
         v-if="$v.input_contact_supplier[errorPropertyDecisionMaker] && input_type == 'contact_supplier'">
      {{ $t("form-errors.required") }}
    </div>
    <!-- !input : contact_supplier -->
  </b-form-group>
</template>

<script>
import controller from "./controller";

export default {
  mixins: [controller]
};
</script>
<style lang="scss">
.tooltip_description_line_container{
  display: grid;
  grid-template-columns: 1fr 1fr;
  span:first-child{
    text-align: left;
    font-weight: bold;
  }
  span:last-child{
    text-align: right;
  }
}

.custom_field_tooltip .tooltip-inner {
  min-width: auto;
  max-width: 400px !important;
}
</style>
