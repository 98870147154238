import { i18n } from "@/utils/i18n";
import { generatePageTitle } from "@/helpers/pageTitle";

const routes = [
  // shipment
  {
    path: "/logistics",
    component: () => import("@/views/app/pages/logistics/dashboard")
  },
  {
    name: "LogisticShipmentListPage",
    path: "/logistics/shipment",
    meta: { page: "shipment", listPage: "logistics", title: generatePageTitle(i18n.t("menu.shipments")) },
    component: () => import("@/views/app/pages/logistics/list/shipments")
  },
  {
    name: "LogisticShipmentNewPage",
    path: "/logistics/shipment/new",
    meta: {
      page: "shipment", title: generatePageTitle(i18n.t("general.new_suffix", { suffix: i18n.t("menu.shipment") }))
    },
    component: () => import("@/views/app/pages/logistics/new")
  },

  // With Custom Fields
  {
    name: "LogisticShipmentNewPageWithCustomFieldGroup",
    path: "/logistics/shipment/new/:custom_field_group_id",
    meta: {
      page: "shipment", title: generatePageTitle(i18n.t("general.new_suffix", { suffix: i18n.t("menu.shipment") }))
    },
    component: () => import("@/views/app/pages/logistics/new")
  },
  {
    path: "/logistics/shipment/:id/products",
    meta: { page: "shipment", permissionKeyPostfix: "shipments" },
    component: () => import("@/views/app/pages/logistics/products")
  },
  {
    name: "LogisticShipmentUpdatePage",
    path: "/logistics/shipment/:id",
    meta: { page: "shipment", permissionKeyPostfix: "shipments", accounting_invoice_trade_type: "purchase" },
    component: () => import("@/views/app/pages/logistics/update")
  },
  // fulfillment
  {
    name: "LogisticFulfillmentListPage",
    path: "/logistics/fulfillment",
    meta: { page: "fulfillment", listPage: "logistics", title: generatePageTitle(i18n.t("menu.fulfillments")) },
    component: () => import("@/views/app/pages/logistics/list/fulfillments")
  },
  {
    name: "LogisticFulfillmentNewPage",
    path: "/logistics/fulfillment/new",
    meta: {
      page: "fulfillment",
      title: generatePageTitle(i18n.t("general.new_suffix", { suffix: i18n.t("menu.fulfillment") }))
    },
    component: () => import("@/views/app/pages/logistics/new")
  },
  {
    name: "LogisticFulfillmentNewPageWithCustomFieldGroup",
    path: "/logistics/fulfillment/new/:custom_field_group_id",
    meta: { page: "fulfillment", title: generatePageTitle(i18n.t("general.new_suffix", { suffix: i18n.t("menu.fulfillment") })) },
    component: () => import("@/views/app/pages/logistics/new")
  },
  {
    path: "/logistics/fulfillment/:id/products",
    meta: { page: "fulfillment", permissionKeyPostfix: "fulfillments" },
    component: () => import("@/views/app/pages/logistics/products")
  },
  {
    name: "LogisticFulfillmentUpdatePage",
    path: "/logistics/fulfillment/:id",
    meta: { page: "fulfillment", permissionKeyPostfix: "fulfillments", accounting_invoice_trade_type: "sale" },
    component: () => import("@/views/app/pages/logistics/update")
  },

  // Transfers
  {
    name: "LogisticTransferListPage",
    path: "/logistics/transfer",
    meta: {
      page: "transfer",
      listPage: "logistics",
      listPageTitleKey: "logistics",
      title : generatePageTitle(i18n.t('menu.transfers'))
    },
    component: () => import("@/views/app/pages/logistics/list/transfers")
  },
  {
    name: "LogisticTransferNewPage",
    path: "/logistics/transfer/new",
    meta: { page: "transfer", title: generatePageTitle(i18n.t("general.new_suffix", { suffix: i18n.t("menu.transfer") })) },
    component: () => import("@/views/app/pages/logistics/new")
  },
  {
    name: "LogisticTransferUpdatePage",
    path: "/logistics/transfer/:id",
    meta: {
      page: "transfer",
      permissionKeyPostfix: "transfers"
    },
    component: () => import("@/views/app/pages/logistics/update")
  },
  {
    path: "/logistics/transfer/:id/products",
    meta: {
      page: "transfer",
      permissionKeyPostfix: "transfers"
    },
    component: () => import("@/views/app/pages/logistics/products")
  }
];

export default routes;
