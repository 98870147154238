import { signinApiUrl } from "@/constants/config";

import axios from "axios";
import CryptoJS from "crypto-js";
import localforage from "localforage";
import { pick } from "lodash";

export default {
  state: {
    activeUser: null,
    processing: false,
    permissions: {},
    saltRounds: 10,
    cryptPassword: "pur350l**//w3ae8"
  },
  getters: {
    processing: state => state.processing,
    isAuthUser: state => (!!state.activeUser ? true : false),
    activeUser: state => state.activeUser,
    activeUserIsCustomer: state => state.activeUser?.user?.type === 'maestro_customer',
    _clientCurrencies: state => state?.activeUser?.client?.currencies?.filter(c => c.enabled),
    _defaultCurrency: state => state?.activeUser?.client?.currencies?.find(c => c?.enabled && c?.primary),
    _multiCurrencyEnabled: state => state?.activeUser?.client?.multi_currency_enabled,
    isWebMaster: state => state.activeUser.user.webmaster,
    cryptPassword: state => state.cryptPassword,
    getAvatar: state => state.activeUser?.user?.avatar,
    clientName(state) {
      if (state?.activeUser?.client?.name === "lamcom_v2") {
        return "lamcom";
      } else {
        return state?.activeUser?.client?.name;
      }
    },
    clientCompanyName(state) {
        return state?.activeUser?.client?.company_name
    }
  },
  mutations: {
    setActiveUser(state, payload) {
      state.activeUser = payload;
      state.permissions = payload.permissions;
    },
    setPermissions(state, pPermissions) {
      state.activeUser.permissions_ = pPermissions;
    },
    updateClient(state, payload) {
      state.activeUser.client = payload.client;
      const cryptedText = CryptoJS.AES.encrypt(JSON.stringify(state.activeUser), state.cryptPassword);
      localStorage.hash = cryptedText.toString();
    },
    setProcessing(state, payload) {
      state.processing = payload;
      state.loginError = null;
    },
  },
  actions: {
    setPermissions({ state }, payload) {
      let user = payload.user;
      let client_name = payload.client_name;
      return new Promise((resolve, reject) => {
        // Setting Permissions...
        if (!user.admin && !user.hasOwnProperty("permissions")) {
          for (let moduleKey in state.permissions) {
            for (let sectionKey in state.permissions[moduleKey]) {
              for (let permissionKey in state.permissions[moduleKey][sectionKey]) {
                state.permissions[moduleKey][sectionKey][permissionKey] = false;
                if (moduleKey == "hive" && client_name == "lamcom_v2") {
                  state.permissions[moduleKey][sectionKey][permissionKey] = true;
                }
              }
            }
          }
          resolve(state.permissions);
        } else if (!user.admin && user.hasOwnProperty("permissions")) {
          for (let moduleKey in state.permissions) {
            for (let sectionKey in state.permissions[moduleKey]) {
              let permission = user.permissions.filter(p => p.policy == sectionKey)[0];
              if (permission) {
                state.permissions[moduleKey][sectionKey] = {
                  all: permission.all || false,
                  to_view: permission.to_view || false,
                  to_update: permission.to_update || false,
                  to_create: permission.to_create || false,
                  to_deactivate: permission.to_deactivate || false,
                  to_activate: permission.to_activate || false
                };
              } else {
                state.permissions[moduleKey][sectionKey] = {
                  all: false,
                  to_view: false,
                  to_update: false,
                  to_create: false,
                  to_deactivate: false,
                  to_activate: false
                };
              }

              if (moduleKey == "hive" && client_name == "lamcom_v2") {
                state.permissions[moduleKey][sectionKey] = {
                  all: true,
                  to_view: true,
                  to_update: true,
                  to_create: true,
                  to_deactivate: true,
                  to_activate: true
                };
              }
            }
          }
          resolve(state.permissions);
        } else if (user.admin) {
          for (let moduleKey in state.permissions) {
            for (let sectionKey in state.permissions[moduleKey]) {
              for (let permissionKey in state.permissions[moduleKey][sectionKey]) {
                state.permissions[moduleKey][sectionKey][permissionKey] = true;
              }
            }
          }
          resolve(state.permissions);
        } else {
          reject();
        }
      });
    },
    updateActiveUser({ state, dispatch, commit }, data) {
      const userData = {
        ...data,
        permissions_: data?.permission_group?.user_permissions || []
      }
      localStorage.hash = CryptoJS.AES.encrypt(JSON.stringify(userData), state.cryptPassword).toString()
      commit('setActiveUser', userData)
      dispatch("setLang", data?.user?.preferred_language || "en");
    },
    login({ commit, dispatch, state }, payload) {
      return new Promise((resolve, reject) => {
        axios.post(signinApiUrl + "/login", pick(payload, ['email', 'password', 'client_name']))
          .then(loginResponse => {
            // Clear All LocalStorage Keys
            const toPath = localStorage.toPath
            localStorage.clear()
            if(toPath) localStorage.toPath = toPath
            Object.keys(localStorage).forEach(k => {
              if(k !== 'toPath') delete localStorage[k]
            })

            localStorage.hash = CryptoJS.AES.encrypt(JSON.stringify(loginResponse.data), state.cryptPassword).toString()
            localStorage.cname = payload.client_name;
            localStorage.medium = loginResponse?.data?.client?.medium;
            localStorage.companyName = loginResponse?.data?.client?.company_name;

            if (payload.remember_me) {
              localStorage.rmHash = CryptoJS.AES.encrypt(JSON.stringify(pick(payload, ['email', 'password', 'remember_me'])), state.cryptPassword).toString()
            } else {
              delete localStorage.rmHash;
            }

            localStorage.token = loginResponse.data?.token;
            dispatch("updateActiveUser", loginResponse.data);
            resolve()
          })
          .catch(e => reject(e))
      })
    },
    signOut() {
      const rmHash = localStorage?.rmHash;
      localStorage.clear();
      if (rmHash) {
        localStorage.rmHash = rmHash;
      }

      localforage.clear()
        .then(() => location.reload())
    }
  }
};
