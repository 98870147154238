export const generatePageTitle = (title) => {
  const clientName = localStorage.cname;
  const companyName = localStorage.companyName || clientName

  const items = [title]

  if(clientName !== "lamcom_v2"){
    items.unshift(companyName)
  }

  return items?.join(" | ")
}
