import { i18n } from "@/utils/i18n";
import helperMixin from "@/mixins/helperMixin";
import { store } from "../../../../../index";
import moment from "moment-timezone";

export default {
  namespaced: true,
  state: {
    endpoint: "/reports/",
    key: "report",
    fetchKey: "reports", // for getting all records from API. reports : [{...},{...},{...}]
    permissionKey: "",
    reportList: [
      {
        name: "sales_by_customer",
        items_field: "contacts_data",
        text: i18n.t("pages.client.custom.lamcom.reports.reportList.sales_by_customer.title"),
        fields: [
          {
            key: "auto_id",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.sales_by_customer.fields.auto_id"),
            sortable: true
          },
          {
            key: "contact_name",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.sales_by_customer.fields.contact_name"),
            sortable: true
          },
          {
            key: "rating",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.sales_by_customer.fields.rating"),
            formatter: v => v || "-"
          },
          {
            key: "industrie",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.sales_by_customer.fields.industrie"),
            formatter: v => v || "-"
          },
          {
            key: "quotes_created",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.sales_by_customer.fields.quotes_created"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: value => {
              return helperMixin.methods._intlNumberFormat(value);
            }
          },
          {
            key: "quotes_won",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.sales_by_customer.fields.quotes_won"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: value => {
              return helperMixin.methods._intlNumberFormat(value);
            }
          },
          {
            key: "quotes_won_percentage",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.sales_by_customer.fields.quotes_won_percentage"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: value => {
              return `${helperMixin.methods._intlPercentFormat(value)}%`;
            }
          },
          {
            key: "work_order_created",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.sales_by_customer.fields.work_order_created"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: value => {
              return helperMixin.methods._intlNumberFormat(value);
            }
          },
          {
            key: "total_redo_work_orders",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.sales_by_customer.fields.total_redo_work_orders"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: value => {
              return helperMixin.methods._intlNumberFormat(value);
            }
          },
          {
            key: "total_declined_work_orders",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.sales_by_customer.fields.total_declined_work_orders"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: value => {
              return helperMixin.methods._intlNumberFormat(value);
            }
          },
          {
            key: "total_cost",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.sales_by_customer.fields.total_cost"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: (value, _, item) => {
              return helperMixin.methods._intlCurrencyFormat(value, item?.currency || "CAD");
            }
          },
          {
            key: "total_sales",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.sales_by_customer.fields.total_sales"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: (value, _, item) => {
              return helperMixin.methods._intlCurrencyFormat(value, item?.currency || "CAD");
            }
          },
          {
            key: "total_credit_wo_tax",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.sales_by_customer.fields.total_credit_wo_tax"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: (value, _, item) => {
              return helperMixin.methods._intlCurrencyFormat(value, item?.currency || "CAD");
            }
          },
          {
            key: "total_actual_sales",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.sales_by_customer.fields.total_actual_sales"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: (value, _, item) => {
              return helperMixin.methods._intlCurrencyFormat(value, item?.currency || "CAD");
            }
          },
          {
            key: "profit_margin",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.sales_by_customer.fields.profit_margin"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: value => {
              return helperMixin.methods._intlPercentFormat(value);
            }
          },
          {
            key: "last_wo_created_date",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.sales_by_customer.fields.last_wo_created_date"),
            sortable: true,
            use_formatter_in_excel: true,
            formatter: value => {
              return helperMixin.methods.reportDateFormat(value);
            }
          },
          {
            key: "sales_people",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.sales_by_customer.fields.sales_people"),
            sortable: true
          }],
        params: [{ name: "date", date_from: true, date_to: true, required: false }]
      },
      {
        name: "sales_by_sale_person",
        items_field: "users_data",
        text: i18n.t("pages.client.custom.lamcom.reports.reportList.sales_by_sale_person.title"),
        fields: [
          {
            key: "user_name",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.sales_by_sale_person.fields.user_name"),
            sortable: true
          },
          {
            key: "total_cost",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.sales_by_sale_person.fields.total_cost"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: (value, _, item) => {
              return helperMixin.methods._intlCurrencyFormat(value, item?.currency || "CAD");
            }
          },
          {
            key: "total_sales",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.sales_by_sale_person.fields.total_sales"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: (value, _, item) => {
              return helperMixin.methods._intlCurrencyFormat(value, item?.currency || "CAD");
            }
          },
          {
            key: "total_credits",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.sales_by_sale_person.fields.total_credits"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: (value, _, item) => {
              return helperMixin.methods._intlCurrencyFormat(value, item?.currency || "CAD");
            }
          },
          {
            key: "total_actual_sales",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.sales_by_sale_person.fields.total_actual_sales"),
            sortable: true
          },
          {
            key: "profit_margin",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.sales_by_sale_person.fields.profit_margin"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: value => {
              return helperMixin.methods._intlPercentFormat(value);
            }
          },
          {
            key: "profit_margin_ext_install_n_shipment",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.sales_by_sale_person.fields.profit_margin_ext_install_n_shipment"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: value => {
              return helperMixin.methods._intlPercentFormat(value);
            }
          },
          {
            key: "quotes_created",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.sales_by_sale_person.fields.quotes_created"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: value => {
              return helperMixin.methods._intlNumberFormat(value);
            }
          },
          {
            key: "quotes_created_price",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.sales_by_sale_person.fields.quotes_created_price"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: (value, _, item) => {
              return helperMixin.methods._intlCurrencyFormat(value, item?.currency || "CAD");
            }
          },
          {
            key: "quotes_won",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.sales_by_sale_person.fields.quotes_won"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: value => {
              return helperMixin.methods._intlNumberFormat(value);
            }
          },
          {
            key: "quotes_won_price",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.sales_by_sale_person.fields.quotes_won_price"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: (value, _, item) => {
              return helperMixin.methods._intlCurrencyFormat(value, item?.currency || "CAD");
            }
          },
          {
            key: "quotes_won_percentage",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.sales_by_sale_person.fields.quotes_won_percentage"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: value => {
              return helperMixin.methods._intlPercentFormat(value);
            }
          },
          {
            key: "work_order_created",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.sales_by_sale_person.fields.work_order_created"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: value => {
              return helperMixin.methods._intlNumberFormat(value);
            }
          },
          // {
          //   key: "work_order_created_by_created_date",
          //   label: i18n.t("pages.client.custom.lamcom.reports.reportList.sales_by_sale_person.fields.work_order_created_by_created_date"),
          //   sortable: true,
          //   use_formatter_in_excel: false,
          //   formatter: (value, key, item) => {
          //     const wocByDate = item?.work_order_created_by_created_date ?? item?.work_order_created_by_cretaed_date;
          //
          //     return wocByDate ? helperMixin.methods._intlNumberFormat(wocByDate) : "-";
          //   }
          // },
          {
            key: "total_redo_work_orders",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.sales_by_sale_person.fields.total_redo_work_orders"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: value => {
              return helperMixin.methods._intlNumberFormat(value);
            }
          },
          {
            key: "total_declined_work_orders",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.sales_by_sale_person.fields.total_declined_work_orders"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: value => {
              return helperMixin.methods._intlNumberFormat(value);
            }
          },
          {
            key: "total_declined_work_orders_percentage",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.sales_by_sale_person.fields.total_declined_work_orders_percentage"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: value => {
              return helperMixin.methods._intlPercentFormat(value);
            }
          }
        ],
        params: [{ name: "date", date_from: true, date_to: true, required: false }]
      }, //! Bu neden contacts_data olarak geliyor Harun'a sor...
      {
        name: "catalog_product_sales",
        items_field: "contacts_data",
        text: i18n.t("pages.client.custom.lamcom.reports.reportList.catalog_product_sales.title"),
        fields: [{
          key: "catalog_id",
          label: i18n.t("pages.client.custom.lamcom.reports.reportList.catalog_product_sales.fields.catalog_id"),
          sortable: true
        },
          {
            key: "categories",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.catalog_product_sales.fields.categories"),
            sortable: true
          },
          {
            key: "catalog_name",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.catalog_product_sales.fields.catalog_name"),
            sortable: true
          },
          {
            key: "quantity_sold",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.catalog_product_sales.fields.quantity_sold"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: value => {
              return helperMixin.methods._intlNumberFormat(value);
            }
          },
          {
            key: "total_cost",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.catalog_product_sales.fields.total_cost"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: (value, _, item) => {
              return helperMixin.methods._intlCurrencyFormat(value, item?.currency || "CAD");
            }
          },
          {
            key: "total_sale",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.catalog_product_sales.fields.total_sale"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: (value, _, item) => {
              return helperMixin.methods._intlCurrencyFormat(value, item?.currency || "CAD");
            }
          },
          {
            key: "profit_margin",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.catalog_product_sales.fields.profit_margin"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: value => {
              return helperMixin.methods._intlPercentFormat(value);
            }
          }],
        params: [{ name: "date", date_from: true, date_to: true, required: false }]
      },
      {
        name: "redo_work_orders",
        items_field: "redo_work_orders",
        text: i18n.t("pages.client.custom.lamcom.reports.reportList.redo_work_orders.title"),
        fields: [{
          key: "id",
          label: i18n.t("pages.client.custom.lamcom.reports.reportList.redo_work_orders.fields.id"),
          sortable: true
        },
          {
            key: "created_at",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.redo_work_orders.fields.created_at"),
            sortable: true,
            use_formatter_in_excel: true,
            formatter: value => {
              return helperMixin.methods.reportDateFormat(value);
            }
          },
          {
            key: "contact",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.redo_work_orders.fields.contact"),
            sortable: true
          },
          {
            key: "project_managers",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.redo_work_orders.fields.project_managers"),
            sortable: true
          },
          {
            key: "redo_reason",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.redo_work_orders.fields.redo_reason"),
            sortable: true
          },
          {
            key: "redo_note",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.redo_work_orders.fields.redo_note"),
            sortable: true
          },
          {
            key: "cost_price",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.redo_work_orders.fields.cost_price"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: (value, _, item) => {
              return helperMixin.methods._intlCurrencyFormat(value, item?.currency || "CAD");
            }
          },
          {
            key: "sale_price",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.redo_work_orders.fields.sale_price"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: (value, _, item) => {
              return helperMixin.methods._intlCurrencyFormat(value, item?.currency || "CAD");
            }
          }],
        params: [{ name: "date", date_from: true, date_to: true, required: false }]
      },
      {
        name: "declined_work_orders",
        items_field: "declined_work_orders",
        text: i18n.t("pages.client.custom.lamcom.reports.reportList.declined_work_orders.title"),
        fields: [{
          key: "id",
          label: i18n.t("pages.client.custom.lamcom.reports.reportList.declined_work_orders.fields.id"),
          sortable: true
        },
          {
            key: "contact",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.declined_work_orders.fields.contact"),
            sortable: true
          },
          {
            key: "sales_people",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.declined_work_orders.fields.sales_people"),
            sortable: true
          },

          {
            key: "declined_by",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.declined_work_orders.fields.declined_by"),
            sortable: true
          },
          {
            key: "decline_note",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.declined_work_orders.fields.decline_note"),
            sortable: true
          },
          {
            key: "declined_reason",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.declined_work_orders.fields.declined_reason"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: value => {
              return value || "-";
            }
          },
          {
            key: "declined_at",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.declined_work_orders.fields.declined_at"),
            sortable: true,
            use_formatter_in_excel: true,
            formatter: value => {
              return helperMixin.methods.reportDateFormat(value, true) || "-";
            }
          },
          {
            key: "total_declined_time",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.declined_work_orders.fields.total_declined_time"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: value => {
              return helperMixin.methods._intlNumberFormat(value, 4) || "-";
            }
          }],
        params: [{ name: "date", date_from: true, date_to: true, required: false }]
      },
      {
        name: "credits",
        items_field: "credits",
        text: i18n.t("pages.client.custom.lamcom.reports.reportList.credits.title"),
        fields: [{
          key: "contact",
          label: i18n.t("pages.client.custom.lamcom.reports.reportList.credits.fields.contact"),
          sortable: true
        },
          {
            key: "sales_people",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.credits.fields.sales_people"),
            sortable: true
          },
          {
            key: "total_credit_wo_tax",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.credits.fields.total_credit_wo_tax"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: (value, _, item) => {
              return helperMixin.methods._intlCurrencyFormat(value, item?.currency || "CAD");
            }
          },
          {
            key: "total_credit_w_tax",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.credits.fields.total_credit_w_tax"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: (value, _, item) => {
              return helperMixin.methods._intlCurrencyFormat(value, item?.currency || "CAD");
            }
          },
          {
            key: "invoice_auto_id",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.credits.fields.invoice_auto_id"),
            sortable: true
          },
          {
            key: "project_auto_id",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.credits.fields.project_auto_id"),
            sortable: true
          },
          {
            key: "issue_date",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.credits.fields.issue_date"),
            sortable: true,
            use_formatter_in_excel: true,
            formatter: value => {
              return helperMixin.methods.reportDateFormat(value);
            }
          },
          {
            key: "note",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.credits.fields.note"),
            sortable: true
          }],
        params: [{ name: "date", date_from: true, date_to: true, required: false }]
      },
      {
        name: "wo_with_additional_graphic_work",
        items_field: "work_orders",
        text: i18n.t("pages.client.custom.lamcom.reports.reportList.wo_with_additional_graphic_work.title"),
        fields: [{
          key: "wo_auto_id",
          label: i18n.t("pages.client.custom.lamcom.reports.reportList.wo_with_additional_graphic_work.fields.wo_auto_id"),
          sortable: true
        },
          {
            key: "contact",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.wo_with_additional_graphic_work.fields.contact"),
            sortable: true
          },
          {
            key: "sales_person",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.wo_with_additional_graphic_work.fields.sales_person"),
            sortable: true
          },
          {
            key: "additional_time_m",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.wo_with_additional_graphic_work.fields.additional_time_m"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: value => {
              return helperMixin.methods._intlNumberFormat(value);
            }
          },
          {
            key: "graphic_person",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.wo_with_additional_graphic_work.fields.graphic_person"),
            sortable: true
          }],
        params: [{ name: "date", date_from: true, date_to: true, required: false }]
      },
      {
        name: "inventory_product_list",
        items_field: "inventory_list",
        text: i18n.t("pages.client.custom.lamcom.reports.reportList.inventory_product_list.title"),
        fields: [{
          key: "auto_id",
          label: i18n.t("pages.client.custom.lamcom.reports.reportList.inventory_product_list.fields.auto_id"),
          sortable: true
        },
          {
            key: "name",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.inventory_product_list.fields.name"),
            sortable: true
          },
          {
            key: "type",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.inventory_product_list.fields.type"),
            sortable: true
          },
          {
            key: "quantity",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.inventory_product_list.fields.quantity"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: value => {
              return helperMixin.methods._intlNumberFormat(value);
            }
          },
          {
            key: "purchase_price",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.inventory_product_list.fields.purchase_price"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: (value, _, item) => {
              return helperMixin.methods._intlCurrencyFormat(value, item?.currency || "CAD");
            }
          },
          {
            key: "total_price",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.inventory_product_list.fields.total_price"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: (value, _, item) => {
              return helperMixin.methods._intlCurrencyFormat(value, item?.currency || "CAD");
            }
          }],
        params: []
      },
      {
        name: "commissions",
        items_field: "commissions",
        text: i18n.t("pages.client.custom.lamcom.reports.reportList.commissions.title"),
        fields: [{
          key: "created_at",
          label: i18n.t("pages.client.custom.lamcom.reports.reportList.commissions.fields.created_at"),
          sortable: true,
          use_formatter_in_excel: true,
          formatter: value => {
            return helperMixin.methods.reportDateFormat(value);
          }
        },
          {
            key: "contact",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.commissions.fields.contact"),
            sortable: true
          },
          {
            key: "invoiced_to_contact",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.commissions.fields.invoiced_to_contact"),
            sortable: true
          },
          {
            key: "quote_id",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.commissions.fields.quote_id"),
            sortable: true
          },
          {
            key: "amount_money",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.commissions.fields.amount_money"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: (value, _, item) => {
              return helperMixin.methods._intlCurrencyFormat(value, item?.currency || "CAD");
            }
          },
          {
            key: "amount_percentage",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.commissions.fields.amount_percentage"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: value => {
              return helperMixin.methods._intlPercentFormat(value);
            }
          },
          {
            key: "project_auto_id",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.commissions.fields.project_auto_id"),
            sortable: true
          },
          {
            key: "project_amount",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.commissions.fields.project_amount"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: (value, _, item) => {
              return helperMixin.methods._intlCurrencyFormat(value, item?.currency || "CAD");
            }
          },
          {
            key: "invoices_amount_wo_tax",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.commissions.fields.invoices_amount_wo_tax"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: (value, _, item) => {
              return helperMixin.methods._intlCurrencyFormat(value, item?.currency || "CAD");
            }
          },
          {
            key: "invoices_amount_w_tax",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.commissions.fields.invoices_amount_w_tax"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: (value, _, item) => {
              return helperMixin.methods._intlCurrencyFormat(value, item?.currency || "CAD");
            }
          },
          {
            key: "invoices",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.commissions.fields.invoices"),
            sortable: true
          },
          {
            key: "purchase_orders",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.commissions.fields.purchase_orders"),
            sortable: true
          }],
        params: [{ name: "date", date_from: true, date_to: true, required: false },
          {
            name: "contact",
            required: false,
            type: "customer"
          }]
      },
      {
        name: "commission_admin",
        items_field: "commissions",
        permission_key: "view_commission_admin",
        text: i18n.t("pages.client.custom.lamcom.reports.reportList.commission_admin.title"),
        fields: [
          {
            key: "created_at",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.commissions.fields.created_at"),
            sortable: true,
            use_formatter_in_excel: true,
            formatter: value => {
              return helperMixin.methods.reportDateFormat(value);
            }
          },
          {
            key: "contact",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.commissions.fields.contact"),
            sortable: true
          },
          {
            key: "invoiced_to_contact",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.commissions.fields.invoiced_to_contact"),
            sortable: true
          },
          {
            key: "quote_id",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.commissions.fields.quote_id"),
            sortable: true
          },
          {
            key: "amount_money",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.commissions.fields.amount_money"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: (value, _, item) => {
              return helperMixin.methods._intlCurrencyFormat(value, item?.currency || "CAD");
            }
          },
          {
            key: "amount_percentage",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.commissions.fields.amount_percentage"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: value => {
              return helperMixin.methods._intlPercentFormat(value);
            }
          },
          {
            key: "project_auto_id",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.commissions.fields.project_auto_id"),
            sortable: true
          },
          {
            key: "project_cost_price",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.commission_admin.fields.project_cost_price"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: (value, _, item) => {
              return helperMixin.methods._intlCurrencyFormat(value, item?.currency || "CAD");
            }
          },
          {
            key: "project_amount",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.commissions.fields.project_amount"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: (value, _, item) => {
              return helperMixin.methods._intlCurrencyFormat(value, item?.currency || "CAD");
            }
          },
          {
            key: "project_profit_margin",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.commission_admin.fields.project_profit_margin"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: (value, _, item) => {
              return helperMixin.methods._intlPercentFormat(value);
            }
          },
          {
            key: "invoices",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.commissions.fields.invoices"),
            sortable: true
          },
          {
            key: "purchase_orders",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.commissions.fields.purchase_orders"),
            sortable: true
          }
        ],
        params: [
          { name: "date", date_from: true, date_to: true, required: false },
          { name: "contact", required: false, type: "customer" }
        ]
      },
      {
        name: "sale_person_summary",
        items_field: "sales_by_contact",
        text: i18n.t("pages.client.custom.lamcom.reports.reportList.sale_person_summary.title"),
        fields: [{
          key: "contact_name",
          label: i18n.t("pages.client.custom.lamcom.reports.reportList.sale_person_summary.fields.contact"),
          sortable: true
        },
          {
            key: "profit_margin",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.sale_person_summary.fields.profit_margin"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: value => {
              return helperMixin.methods._intlPercentFormat(value);
            }
          },
          {
            key: "total_price_w_tax", // label: i18n.t("pages.client.custom.lamcom.reports.reportList.sale_person_summary.fields.invoiced_total_price_w_tax"),
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.sale_person_summary.fields.total_price_w_tax"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: (value, _, item) => {
              return helperMixin.methods._intlCurrencyFormat(value, item?.currency || "CAD");
            }
          },
          {
            key: "total_price_wo_tax",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.sale_person_summary.fields.total_price_wo_tax"), // label: i18n.t("pages.client.custom.lamcom.reports.reportList.sale_person_summary.fields.invoiced_total_price_wo_tax"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: (value, _, item) => {
              return helperMixin.methods._intlCurrencyFormat(value, item?.currency || "CAD");
            }
          },
          {
            key: "total_cost",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.sale_person_summary.fields.total_cost"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: (value, _, item) => {
              return helperMixin.methods._intlCurrencyFormat(value, item?.currency || "CAD");
            }
          }],
        params: [{ name: "date", date_from: true, date_to: true, required: false },
          { name: "user", required: true }]
      },
      {
        name: "production_efficiency",
        items_field: "work_orders",
        text: i18n.t("pages.client.custom.lamcom.reports.reportList.production_efficiency.title"),
        fields: [
          {
            key: "auto_id",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.production_efficiency.fields.auto_id"),
            sortable: true
          },
          {
            key: "contact_name",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.production_efficiency.fields.contact_name"),
            sortable: true
          },
          {
            key: "project_managers",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.production_efficiency.fields.project_managers"),
            sortable: true
          },
          {
            key: "push_production_date",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.production_efficiency.fields.push_production_date"),
            sortable: true,
            use_formatter_in_excel: true,
            formatter: value => {
              return helperMixin.methods.reportDateFormat(value);
            }
          },

          {
            key: "due_date",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.production_efficiency.fields.due_date"),
            sortable: true,
            use_formatter_in_excel: true,
            formatter: value => {
              return helperMixin.methods.reportDateFormat(value);
            }
          },
          {
            key: "graph_finish_date",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.production_efficiency.fields.graph_finish_date"),
            sortable: true,
            use_formatter_in_excel: true,
            formatter: value => {
              return helperMixin.methods.reportDateFormat(value);
            }
          },
          {
            key: "packing_date",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.production_efficiency.fields.packing_date"),
            sortable: true,
            use_formatter_in_excel: true,
            formatter: value => {
              return helperMixin.methods.reportDateFormat(value);
            }
          },
          {
            key: "has_graph",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.production_efficiency.fields.has_graph"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: value => {
              return value === true ? "Yes" : "No";
            }
          },
          {
            key: "has_packing",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.production_efficiency.fields.has_packing"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: value => {
              return value === true ? "Yes" : "No";
            }
          },
          {
            key: "was_on_hold",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.production_efficiency.fields.was_on_hold"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: value => {
              return value === true ? "Yes" : "No";
            }
          },
          {
            key: "total_available_hours",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.production_efficiency.fields.total_available_hours"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: value => {
              return helperMixin.methods._intlNumberFormat(value);
            }
          },
          {
            key: "completion_hours",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.production_efficiency.fields.completion_hours"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: value => {
              return helperMixin.methods._intlNumberFormat(value);
            }
          },
          {
            key: "hold_hours",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.production_efficiency.fields.hold_hours"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: value => {
              return helperMixin.methods._intlNumberFormat(value);
            }
          },
          {
            key: "graph_hours",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.production_efficiency.fields.graph_hours"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: value => {
              return helperMixin.methods._intlNumberFormat(value);
            }
          },
          {
            key: "after_graph_hours",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.production_efficiency.fields.after_graph_hours"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: value => {
              return helperMixin.methods._intlNumberFormat(value);
            }
          },
          {
            key: "due_date_difference_hours",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.production_efficiency.fields.due_date_difference_hours"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: value => {
              return helperMixin.methods._intlNumberFormat(value);
            }
          }],
        params: [{ name: "date", date_from: true, date_to: true, required: false }]
      },
      {
        name: "graphic_people_summary",
        items_field: "users",
        text: i18n.t("pages.client.custom.lamcom.reports.reportList.graphic_people_summary.title"),
        fields: [{
          key: "name",
          label: i18n.t("pages.client.custom.lamcom.reports.reportList.graphic_people_summary.fields.name"),
          sortable: true
        },
          {
            key: "email",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.graphic_people_summary.fields.email"),
            sortable: true
          },
          {
            key: "additional_time_hours",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.graphic_people_summary.fields.additional_time_hours"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: value => {
              return helperMixin.methods._intlNumberFormat(value);
            }
          },
          {
            key: "avr_time_per_wo_mins",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.graphic_people_summary.fields.avr_time_per_wo_mins"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: value => {
              return helperMixin.methods._intlNumberFormat(value);
            }
          },
          {
            key: "creation_time_hours",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.graphic_people_summary.fields.creation_time"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: value => {
              return helperMixin.methods._intlNumberFormat(value);
            }
          },
          {
            key: "declined_count",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.graphic_people_summary.fields.declined_count"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: value => {
              return helperMixin.methods._intlNumberFormat(value);
            }
          },
          {
            key: "regular_time_hours",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.graphic_people_summary.fields.regular_time_hours"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: value => {
              return helperMixin.methods._intlNumberFormat(value);
            }
          },
          {
            key: "layout_time_hours",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.graphic_people_summary.fields.layout_time_hours"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: value => {
              return helperMixin.methods._intlNumberFormat(value);
            }
          },
          {
            key: "total_time_hours",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.graphic_people_summary.fields.total_time_hours"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: value => {
              return helperMixin.methods._intlNumberFormat(value);
            }
          },
          {
            key: "wo_completed_count",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.graphic_people_summary.fields.wo_completed_count"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: value => {
              return helperMixin.methods._intlNumberFormat(value);
            }
          }],
        params: [{ name: "date", date_from: true, date_to: true, required: false }]
      },
      {
        name: "work_in_progress",
        items_field: ["shipments", "work_orders", "service_jobs"],
        text: i18n.t("pages.client.custom.lamcom.reports.reportList.work_in_progress.title"),
        fields: [// Shipments
          {
            belongs_to: "shipments",
            key: "project_id",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.work_in_progress.fields.shipments.project_id"),
            sortable: true
          },
          {
            belongs_to: "shipments",
            key: "cost_price",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.work_in_progress.fields.shipments.cost_price"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: (value, _, item) => {
              return helperMixin.methods._intlCurrencyFormat(value, item?.currency || "CAD");
            }
          },
          {
            belongs_to: "shipments",
            key: "sale_price",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.work_in_progress.fields.shipments.sale_price"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: (value, _, item) => {
              return helperMixin.methods._intlCurrencyFormat(value, item?.currency || "CAD");
            }
          }, // Work Orders

          {
            belongs_to: "work_orders",
            key: "project_auto_id",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.work_in_progress.fields.work_orders.project_auto_id"),
            sortable: true
          },
          {
            belongs_to: "work_orders",
            key: "auto_id",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.work_in_progress.fields.work_orders.auto_id"),
            sortable: true
          },
          {
            belongs_to: "work_orders",
            key: "contact_name",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.work_in_progress.fields.work_orders.contact_name"),
            sortable: true
          },
          {
            belongs_to: "work_orders",
            key: "completed_percentage",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.work_in_progress.fields.work_orders.completed_percentage"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: value => {
              return helperMixin.methods._intlPercentFormat(value);
            }
          },
          {
            belongs_to: "work_orders",
            key: "completed_steps",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.work_in_progress.fields.work_orders.completed_steps"),
            sortable: true
          },
          {
            belongs_to: "work_orders",
            key: "due_date",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.work_in_progress.fields.work_orders.due_date"),
            sortable: true,
            use_formatter_in_excel: true,
            formatter: value => {
              return helperMixin.methods.reportDateFormat(value);
            }
          },
          {
            belongs_to: "work_orders",
            key: "gl_type",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.work_in_progress.fields.work_orders.gl_type"),
            sortable: true
          },

          {
            belongs_to: "work_orders",
            key: "imp_numerique",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.work_in_progress.fields.work_orders.imp_numerique"),
            sortable: true
          },
          {
            belongs_to: "work_orders",
            key: "realized_cost_price",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.work_in_progress.fields.work_orders.realized_cost_price"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: (value, _, item) => {
              return helperMixin.methods._intlCurrencyFormat(value, item?.currency || "CAD");
            }
          },
          {
            belongs_to: "work_orders",
            key: "realized_sale_price",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.work_in_progress.fields.work_orders.realized_sale_price"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: (value, _, item) => {
              return helperMixin.methods._intlCurrencyFormat(value, item?.currency || "CAD");
            }
          },
          {
            belongs_to: "work_orders",
            key: "total_cost_price",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.work_in_progress.fields.work_orders.total_cost_price"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: (value, _, item) => {
              return helperMixin.methods._intlCurrencyFormat(value, item?.currency || "CAD");
            }
          },
          {
            belongs_to: "work_orders",
            key: "total_sale_price",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.work_in_progress.fields.work_orders.total_sale_price"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: (value, _, item) => {
              return helperMixin.methods._intlCurrencyFormat(value, item?.currency || "CAD");
            }
          },
          {
            belongs_to: "work_orders",
            key: "total_steps",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.work_in_progress.fields.work_orders.total_steps"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: value => {
              return helperMixin.methods._intlNumberFormat(value);
            }
          },
          {
            belongs_to: "work_orders",
            key: "travaux_ex_structube",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.work_in_progress.fields.work_orders.travaux_ex_structube"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: value => {
              return helperMixin.methods._intlNumberFormat(value);
            }
          },
          {
            belongs_to: "work_orders",
            key: "status",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.work_in_progress.fields.work_orders.status"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: value => {
              return store.getters["hive_work_orders/statusList"].find(s => s.name === value)?.text || value;
            }
          }, // Service Jobs
          {
            belongs_to: "service_jobs",
            key: "id",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.work_in_progress.fields.service_jobs.id"),
            sortable: true
          },
          {
            belongs_to: "service_jobs",
            key: "project_auto_id",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.work_in_progress.fields.service_jobs.project_auto_id"),
            sortable: true
          },
          {
            belongs_to: "service_jobs",
            key: "start_date",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.work_in_progress.fields.service_jobs.start_date"),
            sortable: true,
            use_formatter_in_excel: true,
            formatter: value => {
              return helperMixin.methods.reportDateFormat(value);
            }
          },
          {
            belongs_to: "service_jobs",
            key: "total_amount",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.work_in_progress.fields.service_jobs.total_amount"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: (value, _, item) => {
              return helperMixin.methods._intlCurrencyFormat(value, item?.currency || "CAD");
            }
          },
          {
            belongs_to: "service_jobs",
            key: "total_cost",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.work_in_progress.fields.service_jobs.total_cost"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: (value, _, item) => {
              return helperMixin.methods._intlCurrencyFormat(value, item?.currency || "CAD");
            }
          }],
        params: [{ name: "date", date_from: false, date_to: true, required: false }]
      },
      {
        name: "contact_export",
        items_field: "contacts",
        text: i18n.t("pages.client.custom.lamcom.reports.reportList.contact_export.title"),
        fields: [{
          key: "auto_id",
          label: i18n.t("pages.client.custom.lamcom.reports.reportList.contact_export.fields.auto_id"),
          sortable: true
        },
          {
            key: "name",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.contact_export.fields.name"),
            sortable: true
          },
          {
            key: "created_at",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.contact_export.fields.created_at"),
            sortable: true,
            use_formatter_in_excel: true,
            formatter: value => {
              return helperMixin.methods.reportDateFormat(value);
            }
          },
          {
            key: "primary_contact_person_name",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.contact_export.fields.primary_contact_person_name"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: value => {
              return value || "-";
            }
          },
          {
            key: "primary_contact_person_email",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.contact_export.fields.primary_contact_person_email"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: value => {
              return value || "-";
            }
          },
          {
            key: "primary_contact_person_type",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.contact_export.fields.primary_contact_person_type"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: value => {
              return value || "-";
            }
          }],
        params: [{ name: "contact_type", required: true }]
      },
      {
        name: "pending_projects_with_invoices",
        permission_key: "pending_projects_with_invoices",
        items_field: "projects",
        text: i18n.t("pages.client.custom.lamcom.reports.reportList.pending_projects_with_invoices.title"),
        fields: [{
          key: "auto_id",
          label: i18n.t("pages.client.custom.lamcom.reports.reportList.pending_projects_with_invoices.fields.auto_id"),
          sortable: true
        },
          {
            key: "name",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.pending_projects_with_invoices.fields.name"),
            sortable: true
          },
          {
            key: "contact",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.pending_projects_with_invoices.fields.contact"),
            sortable: true
          }],
        params: [{ name: "list_type", required: true }]
      },
      {
        name: "customer_specific_sales",
        items_field: "customer_sales",
        text: i18n.t("pages.client.custom.lamcom.reports.reportList.customer_specific_sales.title"),
        fields: [
          {
            key: "project_auto_id",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.customer_specific_sales.fields.project_auto_id"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: value => {
              return value || "-";
            }
          },
          {
            key: "type",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.customer_specific_sales.fields.type"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: (value, fieldName, item) => {
              const title = i18n.t(`pages.client.custom.lamcom.reports.reportList.customer_specific_sales.${value}`);
              if (value === "invoice") return `${title} (${item?.invoice_auto_id})`; else if (value === "credit") return `${title} (${item?.credit_auto_id})`;
            }
          },
          {
            key: "contact",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.customer_specific_sales.fields.contact"),
            sortable: true
          },
          {
            key: "user_reps",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.customer_specific_sales.fields.project_managers"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: value => {
              return value?.join(", ") || "-";
            }
          },
          {
            key: "invoice_contact_person",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.customer_specific_sales.fields.invoice_contact_person"),
            sortable: true
          },
          {
            key: "date",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.customer_specific_sales.fields.date"),
            sortable: true,
            use_formatter_in_excel: true,
            formatter: value => {
              return helperMixin.methods.reportDateFormat(value);
            }
          },
          {
            key: "installation_total_cost",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.customer_specific_sales.fields.installation_total_cost"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: (value, _, item) => {
              return helperMixin.methods._intlCurrencyFormat(value, item?.currency || "CAD");
            }
          },
          {
            key: "installation_total_sale",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.customer_specific_sales.fields.installation_total_sale"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: (value, _, item) => {
              return helperMixin.methods._intlCurrencyFormat(value, item?.currency || "CAD");
            }
          },
          {
            key: "shipment_total_cost",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.customer_specific_sales.fields.shipment_total_cost"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: (value, _, item) => {
              return helperMixin.methods._intlCurrencyFormat(value, item?.currency || "CAD");
            }
          },
          {
            key: "shipment_total_sale",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.customer_specific_sales.fields.shipment_total_sale"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: (value, _, item) => {
              return helperMixin.methods._intlCurrencyFormat(value, item?.currency || "CAD");
            }
          },
          {
            key: "total_cost",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.customer_specific_sales.fields.total_cost"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: (value, _, item) => {
              return helperMixin.methods._intlCurrencyFormat(value, item?.currency || "CAD");
            }
          },
          {
            key: "total_wo_tax",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.customer_specific_sales.fields.total_wo_tax"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: (value, _, item) => {
              return helperMixin.methods._intlCurrencyFormat(value, item?.currency || "CAD");
            }
          },
          {
            key: "profit_margin",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.customer_specific_sales.fields.profit_margin"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: value => {
              return helperMixin.methods._intlPercentFormat(value);
            }
          }],
        params: [
          { name: "contact", required: false, type: "customer" },
          {
            name: "date",
            date_from: true,
            date_to: true,
            required: true
          }
        ]
      },
      {
        name: "installations",
        items_field: "installation_list",
        text: i18n.t("pages.client.custom.lamcom.reports.reportList.installations.title"),
        fields: [{
          key: "auto_id",
          label: i18n.t("pages.client.custom.lamcom.reports.reportList.installations.fields.auto_id"),
          sortable: true
        },
          {
            key: "name",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.installations.fields.name"),
            sortable: true
          },
          {
            key: "contact",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.installations.fields.contact"),
            sortable: true
          },
          {
            key: "project_auto_id",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.installations.fields.project_auto_id"),
            sortable: true
          },
          {
            key: "project_managers",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.installations.fields.project_managers"),
            sortable: true
          },
          {
            key: "is_invoiced",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.installations.fields.is_invoiced"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: value => {
              return value ? i18n.t("pages.client.custom.lamcom.reports.reportList.installations.fields.invoiced") : "-";
            }
          },
          {
            key: "autre_couts",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.installations.fields.autre_couts"),
            sortable: true
          },
          {
            key: "deplacement",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.installations.fields.deplacement"),
            sortable: true
          },
          {
            key: "heures_de_travil",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.installations.fields.heures_de_travil"),
            sortable: true
          },
          {
            key: "taux_horaire",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.installations.fields.taux_horaire"),
            sortable: true
          },
          {
            key: "start_date",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.installations.fields.start_date"),
            sortable: true,
            use_formatter_in_excel: true,
            formatter: value => {
              return helperMixin.methods.reportDateFormat(value);
            }
          },
          {
            key: "due_date",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.installations.fields.due_date"),
            sortable: true,
            use_formatter_in_excel: true,
            formatter: value => {
              return helperMixin.methods.reportDateFormat(value);
            }
          },
          {
            key: "total_cost_price",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.installations.fields.total_cost_price"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: (value, _, item) => {
              return helperMixin.methods._intlCurrencyFormat(value, item?.currency || "CAD");
            }
          },
          {
            key: "total_sale_price",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.installations.fields.total_sale_price"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: (value, _, item) => {
              return helperMixin.methods._intlCurrencyFormat(value, item?.currency || "CAD");
            }
          }],
        params: [{ name: "date", date_from: true, date_to: true, required: true }]
      },
      {
        name: "profitability",
        items_field: null,
        text: i18n.t("pages.client.custom.lamcom.reports.reportList.profitability.title"),
        fields: [],
        params: [{ name: "date", date_from: true, date_to: true, required: true }]
      },
      {
        name: "tpa_qb_online_summary",
        items_field: ["products", "contact_customers", "contact_suppliers", "credits", "final_invocies_purchase", "final_invocies_sale"],
        text: i18n.t("pages.client.custom.lamcom.reports.reportList.tpa_qb_online_summary.title"),
        permission_key: "view_qb_summary_report",
        report_module: "tpa",
        fields: [//! Products
          {
            belongs_to: "products",
            key: "auto_id",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.tpa_qb_online_summary.fields.auto_id"),
            sortable: true
          },
          {
            belongs_to: "products",
            key: "name",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.tpa_qb_online_summary.fields.name"),
            sortable: true
          },
          {
            belongs_to: "products",
            key: "tpa_qb_online.status_from_puresol_to_tpa",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.tpa_qb_online_summary.fields.tpa_qb_online"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: value => store.getters["tpa/statusList"].find(s => s.name === value)?.text || value
          }, //! Contact
          {
            belongs_to: "contact",
            key: "auto_id",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.tpa_qb_online_summary.fields.auto_id"),
            sortable: true
          },
          {
            belongs_to: "contact",
            key: "name",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.tpa_qb_online_summary.fields.name"),
            sortable: true
          },
          {
            belongs_to: "contact",
            key: "due_date",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.tpa_qb_online_summary.fields.due_date"),
            sortable: true,
            use_formatter_in_excel: true,
            formatter: value => {
              return helperMixin.methods.reportDateFormat(value);
            }
          },
          {
            belongs_to: "contact",
            key: "total_amount",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.tpa_qb_online_summary.fields.total_amount"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: (value, _, item) => {
              return helperMixin.methods._intlCurrencyFormat(value, item?.currency || "CAD");
            }
          },
          {
            belongs_to: "contact",
            key: "tpa_qb_online.status_from_puresol_to_tpa",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.tpa_qb_online_summary.fields.tpa_qb_online"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: value => store.getters["tpa/statusList"].find(s => s.name === value)?.text || value
          },

          //! Credits
          {
            belongs_to: "credits",
            key: "auto_id",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.tpa_qb_online_summary.fields.auto_id"),
            sortable: true
          },

          {
            belongs_to: "credits",
            key: "invoice_auto_id",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.tpa_qb_online_summary.fields.invoice_auto_id"),
            sortable: true
          },

          {
            belongs_to: "credits",
            key: "contact_name",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.tpa_qb_online_summary.fields.contact_name"),
            sortable: true
          },

          {
            belongs_to: "credits",
            key: "status",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.tpa_qb_online_summary.fields.status"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: value => store.getters["accounting_credits/status_list"].find(s => s.name === value)?.text || value
          },
          {
            belongs_to: "credits",
            key: "total_amount",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.tpa_qb_online_summary.fields.total_amount"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: (value, _, item) => {
              return helperMixin.methods._intlCurrencyFormat(value, item?.currency || "CAD");
            }
          },
          {
            belongs_to: "credits",
            key: "tpa_qb_online.status_from_puresol_to_tpa",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.tpa_qb_online_summary.fields.tpa_qb_online"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: value => store.getters["tpa/statusList"].find(s => s.name === value)?.text || value
          },

          //! Invoices
          {
            belongs_to: "invoice",
            key: "auto_id",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.tpa_qb_online_summary.fields.auto_id"),
            sortable: true
          },

          {
            belongs_to: "invoice",
            key: "contact",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.tpa_qb_online_summary.fields.contact_name"),
            sortable: true
          },
          {
            belongs_to: "invoice",
            key: "total_amount",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.tpa_qb_online_summary.fields.total_amount"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: (value, _, item) => {
              return helperMixin.methods._intlCurrencyFormat(value, item?.currency || "CAD");
            }
          },
          {
            belongs_to: "invoice",
            key: "tpa_qb_online.status_from_puresol_to_tpa",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.tpa_qb_online_summary.fields.tpa_qb_online"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: value => store.getters["tpa/statusList"].find(s => s.name === value)?.text || value
          },
          {
            belongs_to: "invoice",
            key: "created_at",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.tpa_qb_online_summary.fields.created_at"),
            sortable: true,
            use_formatter_in_excel: true,
            formatter: value => helperMixin.methods.reportDateFormat(value)
          }
        ],
        params: []
      },
      {
        name: "product_purchase_price_change",
        items_field: "products",
        text: i18n.t("pages.client.custom.lamcom.reports.reportList.product_purchase_price_change.title"),
        fields: [
          {
            key: "auto_id",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.product_purchase_price_change.fields.auto_id"),
            sortable: true
          },
          {
            key: "name",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.product_purchase_price_change.fields.name"),
            sortable: true
          },
          {
            key: "type",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.product_purchase_price_change.fields.type"),
            sortable: true
          },
          {
            key: "supplier",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.product_purchase_price_change.fields.supplier"),
            sortable: true
          },
          {
            key: "previous_purchase_bill_id",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.product_purchase_price_change.fields.previous_purchase_bill_id"),
            sortable: true
          },
          {
            key: "previous_purchase_quantity",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.product_purchase_price_change.fields.previous_purchase_quantity"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: value => {
              return helperMixin.methods._intlNumberFormat(value);
            }
          },
          {
            key: "previous_purchase_unit_price",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.product_purchase_price_change.fields.previous_purchase_unit_price"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: (value, _, item) => {
              return helperMixin.methods._intlCurrencyFormat(value, item?.currency || "CAD");
            }
          },
          {
            key: "previous_purchase_cost_sqf_price",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.product_purchase_price_change.fields.previous_purchase_cost_sqf_price"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: (value, _, item) => {
              return helperMixin.methods._intlCurrencyFormat(value, item?.currency || "CAD");
            }
          },
          {
            key: "previous_purchase_date",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.product_purchase_price_change.fields.previous_purchase_date"),
            sortable: true,
            use_formatter_in_excel: true,
            formatter: value => {
              return helperMixin.methods.reportDateFormat(value);
            }
          },
          {
            key: "last_purchase_bill_id",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.product_purchase_price_change.fields.last_purchase_bill_id"),
            sortable: true
          },
          {
            key: "last_purchase_quantity",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.product_purchase_price_change.fields.last_purchase_quantity"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: value => {
              return helperMixin.methods._intlNumberFormat(value);
            }
          },
          {
            key: "last_purchase_unit_price",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.product_purchase_price_change.fields.last_purchase_unit_price"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: (value, _, item) => {
              return helperMixin.methods._intlCurrencyFormat(value, item?.currency || "CAD");
            }
          },
          {
            key: "last_purchase_cost_sqf_price",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.product_purchase_price_change.fields.last_purchase_cost_sqf_price"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: (value, _, item) => {
              return helperMixin.methods._intlCurrencyFormat(value, item?.currency || "CAD");
            }
          },
          {
            key: "last_purchase_date",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.product_purchase_price_change.fields.last_purchase_date"),
            sortable: true,
            use_formatter_in_excel: true,
            formatter: value => {
              return helperMixin.methods.reportDateFormat(value);
            }
          },
          {
            key: "current_stock_quantity",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.product_purchase_price_change.fields.current_stock_quantity"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: value => {
              return helperMixin.methods._intlNumberFormat(value);
            }
          },
          {
            key: "current_cost_price",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.product_purchase_price_change.fields.current_cost_price"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: value => {
              return helperMixin.methods._intlCurrencyFormat(value, "CAD");
            }
          },
          {
            key: "current_sale_price",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.product_purchase_price_change.fields.current_sale_price"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: value => {
              return helperMixin.methods._intlCurrencyFormat(value, "CAD");
            }
          },
          {
            key: "profit_margin",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.product_purchase_price_change.fields.profit_margin"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: value => {
              return helperMixin.methods._intlPercentFormat(value);
            }
          },
          {
            key: "price_fluctuation",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.product_purchase_price_change.fields.price_fluctuation"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: value => {
              return helperMixin.methods._intlNumberFormat(value);
            }
          }],
        params: [// { name: "date", date_from : true, date_to : true, required: true },
          { name: "contact", required: false, type: "supplier" }]
      }, // customer_monthly_sales
      {
        name: "customer_monthly_sales",
        items_field: "customer_sales",
        text: i18n.t("pages.client.custom.lamcom.reports.reportList.customer_monthly_sales.title"),
        fields: [{
          class: "project_auto_id",
          key: "project_auto_id",
          label: i18n.t("pages.client.custom.lamcom.reports.reportList.customer_monthly_sales.fields.project_auto_id"),
          sortable: true
        },
          {
            key: "type",
            class: "type",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.customer_monthly_sales.fields.type"),
            sortable: true
          },
          {
            key: "invoice_auto_id",
            class: "invoice_auto_id",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.customer_monthly_sales.fields.invoice_auto_id"),
            sortable: true
          },
          {
            key: "invoice_contact_person",
            class: "invoice_contact_person",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.customer_monthly_sales.fields.invoice_contact_person"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: value => value || "-"
          },
          {
            key: "date",
            class: "date",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.customer_monthly_sales.fields.date"),
            sortable: true,
            use_formatter_in_excel: true,
            formatter: (value) => {
              return helperMixin.methods.reportDateFormat(value) || "-";
            }
          },
          {
            key: "installation_total_cost",
            class: "installation_total_cost",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.customer_monthly_sales.fields.installation_total_cost"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: (value, _, item) => {
              return helperMixin.methods._intlCurrencyFormat(value, item?.currency || "CAD");
            }
          },
          {
            key: "installation_total_sale",
            class: "installation_total_sale",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.customer_monthly_sales.fields.installation_total_sale"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: (value, _, item) => {
              return helperMixin.methods._intlCurrencyFormat(value, item?.currency || "CAD");
            }
          },
          {
            key: "shipment_total_cost",
            class: "shipment_total_cost",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.customer_monthly_sales.fields.shipment_total_cost"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: (value, _, item) => {
              return helperMixin.methods._intlCurrencyFormat(value, item?.currency || "CAD");
            }
          },
          {
            key: "shipment_total_sale",
            class: "shipment_total_sale",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.customer_monthly_sales.fields.shipment_total_sale"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: (value, _, item) => {
              return helperMixin.methods._intlCurrencyFormat(value, item?.currency || "CAD");
            }
          },
          {
            key: "total_cost",
            class: "total_cost",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.customer_monthly_sales.fields.total_cost"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: (value, _, item) => {
              return helperMixin.methods._intlCurrencyFormat(value, item?.currency || "CAD");
            }
          },
          {
            key: "total_wo_tax",
            class: "total_wo_tax",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.customer_monthly_sales.fields.total_wo_tax"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: (value, _, item) => {
              return helperMixin.methods._intlCurrencyFormat(value, item?.currency || "CAD");
            }
          },
          {
            key: "profit_margin",
            class: "profit_margin",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.customer_monthly_sales.fields.profit_margin"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: value => {
              return helperMixin.methods._intlPercentFormat(value);
            }
          },
          {
            key: "average_order_price",
            class: "average_order_price",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.customer_monthly_sales.fields.average_order_price"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: () => null
          },
          {
            key: "user_reps",
            class: "user_reps",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.customer_monthly_sales.fields.user_reps"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: (value) => value?.join(", ")
          }],
        params: [{ name: "date", date_from: true, date_to: true, required: true },
          {
            name: "show_details",
            required: false
          }]
      },
      {
        name: "purchased_items",
        items_field: "purchased_products",
        text: i18n.t("pages.client.custom.lamcom.reports.reportList.purchased_items.title"),
        fields: [{
          key: "product_auto_id",
          label: i18n.t("pages.client.custom.lamcom.reports.reportList.purchased_items.fields.product_auto_id"),
          sortable: true,
          use_formatter_in_excel: false,
          formatter: (value, _, item) => {
            if (item.type === "product") return value || "-";
            if (item.type === "custom") return i18n.t("menu.custom");

            return "-";
          }
        },
          {
            key: "product_name",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.purchased_items.fields.product_name"),
            sortable: true
          },
          {
            key: "supplier",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.purchased_items.fields.supplier"),
            sortable: true
          },
          {
            key: "total_quantity_purchased",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.purchased_items.fields.total_quantity_purchased"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: value => {
              return helperMixin.methods._intlNumberFormat(value);
            }
          },
          {
            key: "unit_price",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.purchased_items.fields.unit_price"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: (value, _, item) => {
              return helperMixin.methods._intlCurrencyFormat(value, item?.currency || "CAD");
            }
          },
          {
            key: "total_price",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.purchased_items.fields.total_price"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: (value, _, item) => {
              return helperMixin.methods._intlCurrencyFormat(value, item?.currency || "CAD");
            }
          },
          {
            key: "total_purchased_area",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.purchased_items.fields.total_purchased_area"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: value => {
              return helperMixin.methods._intlNumberFormat(value);
            }
          },
          {
            key: "price_per_area_unit",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.purchased_items.fields.price_per_area_unit"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: value => {
              return helperMixin.methods._intlNumberFormat(value);
            }
          }],
        params: [{ name: "contact", required: false, type: "supplier" },
          {
            name: "date",
            date_from: true,
            date_to: true,
            required: true
          }]
      },
      {
        name: "travaux_speciaux",
        items_field: "jobs",
        text: i18n.t("pages.client.custom.lamcom.reports.reportList.travaux_speciaux.title"),
        fields: [
          {
            key: "work_order_auto_id",
            class: "work_order_auto_id",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.travaux_speciaux.fields.work_order_auto_id"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: value => {
              return value || "-";
            }
          },
          {
            key: "work_order_due_date",
            class: "work_order_due_date",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.travaux_speciaux.fields.work_order_due_date"),
            sortable: true,
            use_formatter_in_excel: true,
            formatter: value => helperMixin.methods.reportDateFormat(value)
          },
          {
            key: "user_reps",
            class: "user_reps",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.travaux_speciaux.fields.user_reps"),
            sortable: true,
            use_formatter_in_excel: true,
            formatter: value => value
          },
          {
            key: "contact_name",
            class: "contact_name",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.travaux_speciaux.fields.contact_name"),
            sortable: true
          },
          {
            key: "name",
            class: "name",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.travaux_speciaux.fields.name"),
            sortable: true
          },
          {
            key: "special_work_supplier",
            class: "special_work_supplier",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.travaux_speciaux.fields.travaux_speciaux_fournisseur"),
            sortable: true
          },
          {
            key: "special_work_cost_price",
            class: "special_work_cost_price",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.travaux_speciaux.fields.travaux_speciaux_cost_price"),
            sortable: true,
            formatter: value => helperMixin.methods._intlCurrencyFormat(value, "CAD")
          },
          {
            key: "special_work_sale_price",
            class: "special_work_sale_price",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.travaux_speciaux.fields.travaux_speciaux_sale_price"),
            sortable: true,
            formatter: value => helperMixin.methods._intlCurrencyFormat(value, "CAD")
          }
          // {
          //   key: "cft_5daa1d3e2b7a180004d44379_list",
          //   class: "job_list",
          //   label: i18n.t("pages.client.custom.lamcom.reports.reportList.travaux_speciaux.fields.job_list"),
          //   sortable: false,
          //   use_formatter_in_excel: true,
          //   formatter: list => {
          //     const jobName = i18n.t("pages.client.custom.lamcom.reports.reportList.travaux_speciaux.fields.travaux_speciaux_fournisseur");
          //     const costPrice = i18n.t("pages.client.custom.lamcom.reports.reportList.travaux_speciaux.fields.travaux_speciaux_cost_price");
          //     const salePrice = i18n.t("pages.client.custom.lamcom.reports.reportList.travaux_speciaux.fields.travaux_speciaux_sale_price");
          //     return list?.map(listItem => {
          //       const jobNameValue = listItem?.cf_5daa1d6a2b7a180004d4437a?.name || "-";
          //       const costPriceValue = helperMixin.methods._intlCurrencyFormat(listItem?.cf_5dab0534ed9f277814c96391, "CAD");
          //       const salePriceValue = helperMixin.methods._intlCurrencyFormat(listItem?.cf_5daa1d932b7a180004d4437c, "CAD");
          //       return `${jobName} : ${jobNameValue} | ${costPrice} : ${costPriceValue} | ${salePrice} : ${salePriceValue}`;
          //     })?.join("\n");
          //
          //   }
        ],
        params: [{ name: "date", date_from: true, date_to: true, required: true }]
      },
      {
        name: "work_order_cost",
        items_field: "work_orders",
        text: i18n.t("pages.client.custom.lamcom.reports.reportList.work_order_cost.title"),
        fields: [
          {
            key: "id",
            class: "id",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.work_order_cost.fields.id"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: value => {
              return value || "-";
            }
          },
          {
            key: "project_id",
            class: "project_id",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.work_order_cost.fields.project_id"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: value => {
              return value || "-";
            }
          },
          {
            key: "name",
            class: "name",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.work_order_cost.fields.name"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: value => {
              return value || "-";
            }
          },
          {
            key: "due_date",
            class: "due_date",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.work_order_cost.fields.due_date"),
            sortable: true,
            use_formatter_in_excel: true,
            formatter: value => {
              return helperMixin.methods.reportDateFormat(value);
            }
          },
          {
            key: "contact",
            class: "contact",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.work_order_cost.fields.contact"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: value => {
              return value || "-";
            }
          },
          {
            key: "user_reps",
            class: "user_reps",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.work_order_cost.fields.user_reps"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: value => {
              return value || "-";
            }
          },
          {
            key: "status",
            class: "status",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.work_order_cost.fields.status"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: value => {
              return value || "-";
            }
          },
          {
            key: "total_sale_price",
            class: "total_sale_price",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.work_order_cost.fields.total_sale_price"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: (value, _, item) => {
              return !["n/a", "-"].includes(value) ? helperMixin.methods._intlCurrencyFormat(value, item?.currency || "CAD") : "-";
            }
          },
          {
            key: "total_cost_price",
            class: "total_cost_price",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.work_order_cost.fields.total_cost_price"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: (value, _, item) => {
              return !["n/a", "-"].includes(value) ? helperMixin.methods._intlCurrencyFormat(value, item?.currency || "CAD") : "-";
            }
          },
          {
            key: "profit_margin",
            class: "profit_margin",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.work_order_cost.fields.profit_margin"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: value => {
              return value ? helperMixin.methods._intlNumberFormat(value) : "-";
            }
          },
          {
            key: "total_sqf",
            class: "total_sqf",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.work_order_cost.fields.total_sqf"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: value => {
              return helperMixin.methods._intlNumberFormat(value);
            }
          },
          {
            key: "step_graphic_1_up_recorded_time",
            class: "step_graphic_1_up_recorded_time",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.work_order_cost.fields.step_graphic_1_up_recorded_time"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: value => {
              return !["n/a", "-"].includes(value) ? helperMixin.methods._intlNumberFormat(value) : "-";
            }
          },
          {
            key: "step_graphic_print_layout_recorded_time",
            class: "step_graphic_print_layout_recorded_time",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.work_order_cost.fields.step_graphic_print_layout_recorded_time"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: value => {
              return !["n/a", "-"].includes(value) ? helperMixin.methods._intlNumberFormat(value) : "-";
            }
          },
          {
            key: "material_ink_cost",
            class: "material_ink_cost",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.work_order_cost.fields.material_ink_cost"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: (value, _, item) => {
              return !["n/a", "-"].includes(value) ? helperMixin.methods._intlCurrencyFormat(value, item?.currency || "CAD") : "-";
            }
          },
          {
            key: "material_cost",
            class: "material_cost",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.work_order_cost.fields.material_cost"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: (value, _, item) => {
              return !["n/a", "-"].includes(value) ? helperMixin.methods._intlCurrencyFormat(value, item?.currency || "CAD") : "-";
            }
          },
          {
            key: "material_sqf_cost",
            class: "material_sqf_cost",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.work_order_cost.fields.material_sqf_cost"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: (value, _, item) => {
              return !["n/a", "-"].includes(value) ? helperMixin.methods._intlCurrencyFormat(value, item?.currency || "CAD") : "-";
            }
          },
          {
            key: "material_colorado_print_cost",
            class: "material_colorado_print_cost",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.work_order_cost.fields.material_colorado_print_cost"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: (value, _, item) => {
              return !["n/a", "-"].includes(value) ? helperMixin.methods._intlCurrencyFormat(value, item?.currency || "CAD") : "-";
            }
          },
          {
            key: "step_printing_colorado_recorded_time",
            class: "step_printing_colorado_recorded_time",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.work_order_cost.fields.step_printing_colorado_recorded_time"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: value => {
              return !["n/a", "-"].includes(value) ? helperMixin.methods._intlNumberFormat(value) : "-";
            }
          },
          {
            key: "step_printing_colorado_used_material_in_sqf",
            class: "step_printing_colorado_used_material_in_sqf",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.work_order_cost.fields.step_printing_colorado_used_material_in_sqf"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: value => value || "-"
          },
          {
            key: "material_teleios_print_cost",
            class: "material_teleios_print_cost",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.work_order_cost.fields.material_teleios_print_cost"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: (value, _, item) => {
              return !["n/a", "-"].includes(value) ? helperMixin.methods._intlCurrencyFormat(value, item?.currency || "CAD") : "-";
            }
          },
          {
            key: "step_printing_teleios_recorded_time",
            class: "step_printing_teleios_recorded_time",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.work_order_cost.fields.step_printing_teleios_recorded_time"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: value => {
              return !["n/a", "-"].includes(value) ? helperMixin.methods._intlNumberFormat(value) : "-";
            }
          },
          {
            key: "step_printing_teleios_used_material_in_sqf",
            class: "step_printing_teleios_used_material_in_sqf",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.work_order_cost.fields.step_printing_teleios_used_material_in_sqf"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: value => value || "-"
          },
          {
            key: "material_vutek_roll_print_cost",
            class: "material_vutek_roll_print_cost",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.work_order_cost.fields.material_vutek_roll_print_cost"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: (value, _, item) => {
              return !["n/a", "-"].includes(value) ? helperMixin.methods._intlCurrencyFormat(value, item?.currency || "CAD") : "-";
            }
          },
          {
            key: "step_vutek_roll_recorded_time",
            class: "step_vutek_roll_recorded_time",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.work_order_cost.fields.step_vutek_roll_recorded_time"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: value => {
              return !["n/a", "-"].includes(value) ? helperMixin.methods._intlNumberFormat(value) : "-";
            }
          },
          {
            key: "step_vutek_roll_used_material_in_sqf",
            class: "step_vutek_roll_used_material_in_sqf",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.work_order_cost.fields.step_vutek_roll_used_material_in_sqf"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: value => value || "-"
          },
          {
            key: "material_vutek_flatbed_print_cost",
            class: "material_vutek_flatbed_print_cost",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.work_order_cost.fields.material_vutek_flatbed_print_cost"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: (value, _, item) => {
              return !["n/a", "-"].includes(value) ? helperMixin.methods._intlCurrencyFormat(value, item?.currency || "CAD") : "-";
            }
          },
          {
            key: "step_printing_vuetek_flatbed_recorded_time",
            class: "step_printing_vuetek_flatbed_recorded_time",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.work_order_cost.fields.step_printing_vuetek_flatbed_recorded_time"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: value => {
              return !["n/a", "-"].includes(value) ? helperMixin.methods._intlNumberFormat(value) : "-";
            }
          },
          {
            key: "step_printing_vutek_flatbed_used_material_in_sqf",
            class: "step_printing_vutek_flatbed_used_material_in_sqf",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.work_order_cost.fields.step_printing_vutek_flatbed_used_material_in_sqf"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: value => value || "-"
          },
          {
            key: "material_qprint_nyala_print_cost",
            class: "material_qprint_nyala_print_cost",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.work_order_cost.fields.material_qprint_nyala_print_cost"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: (value, _, item) => {
              return !["n/a", "-"].includes(value) ? helperMixin.methods._intlCurrencyFormat(value, item?.currency || "CAD") : "-";
            }
          },
          {
            key: "step_swiss_qprint_nyala_recorded_time",
            class: "step_swiss_qprint_nyala_recorded_time",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.work_order_cost.fields.step_swiss_qprint_nyala_recorded_time"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: value => {
              return !["n/a", "-"].includes(value) ? helperMixin.methods._intlNumberFormat(value) : "-";
            }
          },
          {
            key: "step_swiss_qprint_nyala_used_material_in_sqf",
            class: "step_swiss_qprint_nyala_used_material_in_sqf",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.work_order_cost.fields.step_swiss_qprint_nyala_used_material_in_sqf"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: value => value || "-"
          },
          // @TODO: Difference Material, estimate vs Reel - burayı bulamadım
          {
            key: "difference_materiel_estimate_vs_reel_sqf",
            class: "difference_materiel_estimate_vs_reel_sqf",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.work_order_cost.fields.difference_materiel_estimate_vs_reel_sqf"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: value => {
              return !["n/a", "-"].includes(value) ? helperMixin.methods._intlNumberFormat(value) : "-";
            }
          },
          {
            key: "difference_materiel_estimate_vs_reel_price",
            class: "difference_materiel_estimate_vs_reel_price",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.work_order_cost.fields.difference_materiel_estimate_vs_reel_price"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: (value, _, item) => {
              return !["n/a", "-"].includes(value) ? helperMixin.methods._intlCurrencyFormat(value, item?.currency || "CAD") : "-";
            }
          },
          {
            key: "decoupe_zund_estimated_cost",
            class: "decoupe_zund_estimated_cost",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.work_order_cost.fields.decoupe_zund_estimated_cost"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: (value, _, item) => {
              return !["n/a", "-"].includes(value) ? helperMixin.methods._intlCurrencyFormat(value, item?.currency || "CAD") : "-";
            }
          },
          {
            key: "step_decoupe_zund_recorded_time",
            class: "step_decoupe_zund_recorded_time",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.work_order_cost.fields.step_decoupe_zund_recorded_time"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: value => {
              return !["n/a", "-"].includes(value) ? helperMixin.methods._intlNumberFormat(value) : "-";
            }
          },
          {
            key: "decoupe_zund_estimated_vs_reel",
            class: "decoupe_zund_estimated_vs_reel",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.work_order_cost.fields.decoupe_zund_estimated_vs_reel"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: value => {
              return !["n/a", "-"].includes(value) ? helperMixin.methods._intlNumberFormat(value) : "-";
            }
          },
          {
            key: "decoupe_kongsberg_estimated_cost",
            class: "decoupe_kongsberg_estimated_cost",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.work_order_cost.fields.decoupe_kongsberg_estimated_cost"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: (value, _, item) => {
              return !["n/a", "-"].includes(value) ? helperMixin.methods._intlCurrencyFormat(value, item?.currency || "CAD") : "-";
            }
          },
          {
            key: "step_step_decoupe_kongsberg_recorded_time",
            class: "step_step_decoupe_kongsberg_recorded_time",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.work_order_cost.fields.step_step_decoupe_kongsberg_recorded_time"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: value => {
              return !["n/a", "-"].includes(value) ? helperMixin.methods._intlNumberFormat(value) : "-";
            }
          },
          {
            key: "decoupe_kongsberg_estimated_vs_reel",
            class: "decoupe_kongsberg_estimated_vs_reel",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.work_order_cost.fields.decoupe_kongsberg_estimated_vs_reel"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: value => {
              return !["n/a", "-"].includes(value) ? helperMixin.methods._intlNumberFormat(value) : "-";
            }
          },
          {
            key: "decoupe_lazer_tissue_estimated_cost",
            class: "decoupe_lazer_tissue_estimated_cost",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.work_order_cost.fields.decoupe_lazer_tissue_estimated_cost"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: (value, _, item) => {
              return !["n/a", "-"].includes(value) ? helperMixin.methods._intlCurrencyFormat(value, item?.currency || "CAD") : "-";
            }
          },
          {
            key: "step_step_matic_recorded_time",
            class: "step_step_matic_recorded_time",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.work_order_cost.fields.step_step_matic_recorded_time"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: value => {
              return !["n/a", "-"].includes(value) ? helperMixin.methods._intlNumberFormat(value) : "-";
            }
          },
          {
            key: "decoupe_lazer_tissue_estimated_vs_reel",
            class: "decoupe_lazer_tissue_estimated_vs_reel",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.work_order_cost.fields.decoupe_lazer_tissue_estimated_vs_reel"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: value => {
              return !["n/a", "-"].includes(value) ? helperMixin.methods._intlNumberFormat(value) : "-";
            }
          },
          {
            key: "decoupe_cnc_protek_estimated_cost",
            class: "decoupe_cnc_protek_estimated_cost",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.work_order_cost.fields.decoupe_cnc_protek_estimated_cost"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: (value, _, item) => {
              return !["n/a", "-"].includes(value) ? helperMixin.methods._intlCurrencyFormat(value, item?.currency || "CAD") : "-";
            }
          },
          {
            key: "step_decoupe_cnc_protek_recorded_time",
            class: "step_decoupe_cnc_protek_recorded_time",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.work_order_cost.fields.step_decoupe_cnc_protek_recorded_time"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: value => {
              return !["n/a", "-"].includes(value) ? helperMixin.methods._intlNumberFormat(value) : "-";
            }
          },
          {
            key: "decoupe_cnc_protek_estimated_vs_reel",
            class: "decoupe_cnc_protek_estimated_vs_reel",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.work_order_cost.fields.decoupe_cnc_protek_estimated_vs_reel"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: value => {
              return !["n/a", "-"].includes(value) ? helperMixin.methods._intlNumberFormat(value) : "-";
            }
          },
          {
            key: "decoupe_de_vinyle_cost",
            class: "decoupe_de_vinyle_cost",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.work_order_cost.fields.decoupe_de_vinyle_cost"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: (value, _, item) => {
              return !["n/a", "-"].includes(value) ? helperMixin.methods._intlCurrencyFormat(value, item?.currency || "CAD") : "-";
            }
          },
          {
            key: "decoupe_de_vinyle_difficulty_level",
            class: "decoupe_de_vinyle_difficulty_level",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.work_order_cost.fields.decoupe_de_vinyle_difficulty_level"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: value => {
              return value || "-";
            }
          },
          {
            key: "step_decoupe_vinyle_recorded_time",
            class: "step_decoupe_vinyle_recorded_time",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.work_order_cost.fields.step_decoupe_vinyle_recorded_time"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: value => {
              return !["n/a", "-"].includes(value) ? helperMixin.methods._intlNumberFormat(value) : "-";
            }
          },
          {
            key: "quincaillerie_cost",
            class: "quincaillerie_cost",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.work_order_cost.fields.quincaillerie_cost"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: (value, _, item) => {
              return !["n/a", "-"].includes(value) ? helperMixin.methods._intlCurrencyFormat(value, item?.currency || "CAD") : "-";
            }
          },
          {
            key: "finition_signaletique_cost",
            class: "finition_signaletique_cost",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.work_order_cost.fields.finition_signaletique_cost"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: (value, _, item) => {
              return !["n/a", "-"].includes(value) ? helperMixin.methods._intlCurrencyFormat(value, item?.currency || "CAD") : "-";
            }
          },
          {
            key: "finition_signaletique_estimated_time",
            class: "finition_signaletique_estimated_time",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.work_order_cost.fields.finition_signaletique_estimated_time"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: value => {
              return !["n/a", "-"].includes(value) ? helperMixin.methods._intlNumberFormat(value) : "-";
            }
          },
          {
            key: "step_finition_signalitique_recorded_time",
            class: "step_finition_signalitique_recorded_time",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.work_order_cost.fields.step_finition_signalitique_recorded_time"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: value => {
              return !["n/a", "-"].includes(value) ? helperMixin.methods._intlNumberFormat(value) : "-";
            }
          },
          {
            key: "difference_sign_estimate_vs_r",
            class: "difference_sign_estimate_vs_r",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.work_order_cost.fields.difference_sign_estimate_vs_r"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: (value, _, item) => {
              !["n/a", "-"].includes(value) ? helperMixin.methods._intlCurrencyFormat(value, item?.currency || "CAD") : "-";
            }
          },
          {
            key: "peinture_estimated_cost",
            class: "peinture_estimated_cost",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.work_order_cost.fields.peinture_estimated_cost"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: (value, _, item) => {
              return !["n/a", "-"].includes(value) ? helperMixin.methods._intlCurrencyFormat(value, item?.currency || "CAD") : "-";
            }
          },
          {
            key: "peinture_estimated_time",
            class: "peinture_estimated_time",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.work_order_cost.fields.peinture_estimated_time"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: value => {
              return !["n/a", "-"].includes(value) ? helperMixin.methods._intlNumberFormat(value) : "-";
            }
          },
          {
            key: "step_peinture_recorded_time",
            class: "step_peinture_recorded_time",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.work_order_cost.fields.step_peinture_recorded_time"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: value => {
              return !["n/a", "-"].includes(value) ? helperMixin.methods._intlNumberFormat(value) : "-";
            }
          },
          {
            key: "difference_peinture_temps",
            class: "difference_peinture_temps",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.work_order_cost.fields.difference_peinture_temps"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: value => {
              return !["n/a", "-"].includes(value) ? helperMixin.methods._intlNumberFormat(value) : "-";
            }
          },
          {
            key: "autres_frais_type",
            class: "autres_frais_type",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.work_order_cost.fields.autres_frais_type"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: value => value || "-"
          },
          {
            key: "autres_frais_cost",
            class: "autres_frais_cost",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.work_order_cost.fields.autres_frais_cost"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: (value, _, item) => {
              return !["n/a", "-"].includes(value) ? helperMixin.methods._intlCurrencyFormat(value, item?.currency || "CAD") : "-";
            }
          },
          {
            key: "mise_en_kit_estimated_time",
            class: "mise_en_kit_estimated_time",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.work_order_cost.fields.mise_en_kit_estimated_time"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: (value) => {
              return !["n/a", "-"].includes(value) ? helperMixin.methods._intlNumberFormat(value) : "-";
            }
          },
          {
            key: "emballage_type",
            class: "emballage_type",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.work_order_cost.fields.emballage_type"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: value => {
              return value || "-";
            }
          },
          {
            key: "emballage_estimated_cost",
            class: "emballage_estimated_cost",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.work_order_cost.fields.emballage_estimated_cost"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: (value, _, item) => {
              return !["n/a", "-"].includes(value) ? helperMixin.methods._intlCurrencyFormat(value, item?.currency || "CAD") : "-";
            }
          }
        ],
        params: [
          { name: "date", date_from: true, date_to: true, required: true },
          { name: "contact", required: false, type: "customer" }
        ]
      },
      {
        name: "metro_transaction_summary",
        items_field: "transactions",
        text: i18n.t("pages.client.custom.lamcom.reports.reportList.metro_transaction_summary.title"),
        fields: [
          {
            key: "facture_ou_credit",
            class: "facture_ou_credit",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.metro_transaction_summary.fields.facture_ou_credit"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: v => v || "-"
          },
          {
            key: "auto_id",
            class: "type",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.metro_transaction_summary.fields.type"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: v => v || "-"
          },

          {
            key: "transaction_date",
            class: "transaction_date",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.metro_transaction_summary.fields.transaction_date"),
            sortable: true,
            use_formatter_in_excel: true,
            formatter: v => v ? +moment(v).format("YYYYMMDD") : "-"
          },
          {
            key: "customer_po",
            class: "customer_po",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.metro_transaction_summary.fields.customer_po"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: v => v || "-"
          },
          {
            key: "store",
            class: "store",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.metro_transaction_summary.fields.store"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: v => v || "-"
          },
          {
            key: "tax_tps_tvh",
            class: "tax_tps_tvh",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.metro_transaction_summary.fields.tax_tps_tvh"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: v => helperMixin.methods._intlCurrencyFormat(v, "CAD")
          },
          {
            key: "tax_tvq",
            class: "tax_tvq",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.metro_transaction_summary.fields.tax_tvq"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: v => helperMixin.methods._intlCurrencyFormat(v, "CAD")
          },
          {
            key: "sale_price",
            class: "sale_price",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.metro_transaction_summary.fields.sale_price"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: v => helperMixin.methods._intlCurrencyFormat(v, "CAD")
          }
        ],
        params: [
          { name: "date", date_from: true, date_to: true, required: true },
          { name: "contact", required: true, type: "customer", multiple: true }
        ]
      },
      {
        name: "project_shipping_report",
        items_field: "project_shipments",
        text: i18n.t("pages.client.custom.lamcom.reports.reportList.project_shipping_report.title"),
        fields: [
          {
            key: "project_auto_id",
            class: "project_auto_id",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.project_shipping_report.fields.project_auto_id"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: v => v || "-"
          },
          {
            key: "invoice_date",
            class: "invoice_date",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.project_shipping_report.fields.invoice_date"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: v => helperMixin.methods.reportDateFormat(v)
          },
          {
            key: "contact_name",
            class: "contact_name",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.project_shipping_report.fields.contact_name"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: v => v || "-"
          },
          {
            key: "shipping_type",
            class: "shipping_type",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.project_shipping_report.fields.shipping_type"),
            sortable: true,
            use_formatter_in_excel: true,
            formatter: v => store.getters["hive_projects/shippingTypeList"].find(s => s.id === v)?.text || v || "-"
          },
          {
            key: "shipping_service",
            class: "shipping_service",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.project_shipping_report.fields.shipping_service"),
            sortable: true,
            use_formatter_in_excel: true,
            formatter: v => store.getters["hive_projects/shippingServiceList"].find(s => s.id === v)?.text || v || "-"
          },
          {
            key: "shipping_carrier",
            class: "shipping_carrier",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.project_shipping_report.fields.shipping_carrier"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: v => v || "-"
          },
          {
            key: "shipping_cost",
            class: "shipping_cost",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.project_shipping_report.fields.shipping_cost"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: (v, _, item) => helperMixin.methods._intlCurrencyFormat(v, item?.currency || "CAD")
          },
          {
            key: "shipping_price_sale_price",
            class: "shipping_price_sale_price",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.project_shipping_report.fields.shipping_price_sale_price"),
            sortable: true,
            use_formatter_in_excel: true,
            formatter: (v, _, item) => helperMixin.methods._intlCurrencyFormat(v, item?.currency || "CAD")
          }
        ],
        params: [
          { name: "date", date_from: true, date_to: true, required: true }
        ]
      },
      {
        name: "production_monitoring",
        items_field: "work_orders",
        text: i18n.t("pages.client.custom.lamcom.reports.reportList.production_monitoring.title"),
        fields: [
          {
            key: "work_order_id",
            class: "work_order_id",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.production_monitoring.fields.work_order_id"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: v => v || "-"
          },
          {
            key: "material_name",
            class: "material_name",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.production_monitoring.fields.material_name"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: v => v || "-"
          },
          {
            key: "total_quantity",
            class: "total_quantity",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.production_monitoring.fields.total_quantity"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: v => helperMixin.methods._intlNumberFormat(v)
          },
          {
            key: "total_area",
            class: "total_area",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.production_monitoring.fields.total_area"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: v => helperMixin.methods._intlNumberFormat(v)
          },
          {
            key: "printer_name",
            class: "printer_name",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.production_monitoring.fields.printer_name"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: v => v || "-"
          },
          {
            key: "ink_name_1",
            class: "ink_name",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.production_monitoring.fields.ink_name_1"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: v => v || "-"
          },
          {
            key: "ink_name_2",
            class: "ink_name",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.production_monitoring.fields.ink_name_2"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: v => v || "-"
          },
          {
            key: "ink_name_3",
            class: "ink_name",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.production_monitoring.fields.ink_name_3"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: v => v || "-"
          },
          {
            key: "total_estimated_print_time_mins",
            class: "total_estimated_print_time_mins",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.production_monitoring.fields.total_estimated_print_time_mins"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: v => helperMixin.methods._intlNumberFormat(v)
          },
          {
            key: "step_printing_recorded_time",
            class: "step_printing_recorded_time",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.production_monitoring.fields.step_printing_recorded_time"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: v => helperMixin.methods._intlNumberFormat(v)
          },
          {
            key: "step_printing_completed_by",
            class: "step_printing_completed_by",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.production_monitoring.fields.step_printing_completed_by"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: v => v || "-"
          },
          {
            key: "cutting_machine_name",
            class: "cutting_machine_name",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.production_monitoring.fields.cutting_machine_name"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: v => v || "-"
          },
          {
            key: "total_estimated_cut_time_mins",
            class: "total_estimated_cut_time_mins",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.production_monitoring.fields.total_estimated_cut_time_mins"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: v => helperMixin.methods._intlNumberFormat(v)
          },
          {
            key: "step_cut_recorded_time",
            class: "step_cut_recorded_time",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.production_monitoring.fields.step_cut_recorded_time"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: v => helperMixin.methods._intlNumberFormat(v)
          },
          {
            key: "step_cut_completed_by",
            class: "step_cut_completed_by",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.production_monitoring.fields.step_cut_completed_by"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: v => v || "-"
          },
          {
            key: "archive",
            class: "archive",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.production_monitoring.fields.archive"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: v => v || "-"
          }
        ],
        params: [
          { name: "date", date_from: true, date_to: true, required: true },
          { name: "user", required: true }
        ]
      },
      {
        name: "inventory_report_per_date",
        items_field: "products",
        permission_key: "view_inventory_report_per_date",
        text: i18n.t("pages.client.custom.lamcom.reports.reportList.inventory_report_per_date.title"),
        fields: [
          {
            key: "auto_id",
            class: "auto_id",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.inventory_report_per_date.fields.auto_id"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: v => v || "-"
          },
          {
            key: "name",
            class: "name",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.inventory_report_per_date.fields.name"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: v => v || "-"
          },
          {
            key: "status",
            class: "status",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.inventory_report_per_date.fields.status"),
            sortable: true,
            use_formatter_in_excel: true,
            formatter: value => {
              return store.getters["inventory_products/statusList"].find(s => s.name === value)?.text || value;
            }
          },
          {
            key: "type",
            class: "type",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.inventory_report_per_date.fields.type"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: v => v || "-"
          },
          {
            key: "quantity",
            class: "quantity",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.inventory_report_per_date.fields.quantity"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: v => v
          },
          {
            key: "price",
            class: "price",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.inventory_report_per_date.fields.price"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: (value, _, item) => {
              return helperMixin.methods._intlCurrencyFormat(value, item?.currency || "CAD");
            }
          },
          {
            key: "total_price",
            class: "total_price",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.inventory_report_per_date.fields.total_price"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: (value, _, item) => {
              return helperMixin.methods._intlCurrencyFormat(value, item?.currency || "CAD");
            }
          }
        ],
        params: [
          { name: "date", date: true, time: true, required: true }
        ]
      },
      {
        name: "job_items_pricing",
        items_field: "jobs",
        permission_key: "view_inventory_report_per_date",
        text: i18n.t("pages.client.custom.lamcom.reports.reportList.job_items_pricing.title"),
        fields: [
          {
            key: "work_order.auto_id",
            class: "auto_id",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.job_items_pricing.fields.auto_id"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: v => v || "-"
          },
          {
            key: "customer.name",
            class: "customer",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.job_items_pricing.fields.customer"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: v => v || "-"
          },
          {
            key: "name",
            class: "name",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.job_items_pricing.fields.name"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: v => v || "-"
          },
          {
            key: "material",
            class: "material",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.job_items_pricing.fields.material"),
            sortable: true,
            use_formatter_in_excel: true,
            formatter: (v, _, item) => {
              return v ? `${v.name} | #${v.auto_id}` : "-";
            }
          },
          {
            key: "total_suggested_price",
            class: "total_suggested_price",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.job_items_pricing.fields.total_suggested_price"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: (value, _, item) => {
              return helperMixin.methods._intlCurrencyFormat(value, item?.currency || "CAD");
            }
          },
          {
            key: "total_adjusted_price",
            class: "total_adjusted_price",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.job_items_pricing.fields.total_adjusted_price"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: (value, _, item) => {
              return helperMixin.methods._intlCurrencyFormat(value, item?.currency || "CAD");
            }
          },
          {
            key: "difference_suggested_price",
            class: "difference_suggested_price",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.job_items_pricing.fields.difference_suggested_price"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: (value, _, item) => {
              return helperMixin.methods._intlPercentFormat(value);
            }
          },
          {
            key: "adjusted_price_pi2",
            class: "adjusted_price_pi2",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.job_items_pricing.fields.adjusted_price_pi2"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: (value, _, item) => {
              return helperMixin.methods._intlCurrencyFormat(value, item?.currency || "CAD");
            }
          },
          {
            key: "profit_margin",
            class: "profit_margin",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.job_items_pricing.fields.profit_margin"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: (value, _, item) => {
              return helperMixin.methods._intlPercentFormat(value);
            }
          },
          {
            key: "project_managers",
            class: "project_managers",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.job_items_pricing.fields.project_managers"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: v => v || "-"
          },
          {
            key: "work_order.created_at",
            class: "created_at",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.job_items_pricing.fields.created_at"),
            sortable: true,
            use_formatter_in_excel: true,
            formatter: v => helperMixin.methods.reportDateFormat(v)
          },
          {
            key: "work_order.due_date",
            class: "due_date",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.job_items_pricing.fields.due_date"),
            sortable: true,
            use_formatter_in_excel: true,
            formatter: v => helperMixin.methods.reportDateFormat(v)
          }
        ],
        params: [
          { name: "date", date_from: true, date_to: true, required: true }
        ]
      },
      {
        name: "quote_summary_per_customer",
        items_field: "quotes_data",
        // permission_key: "view_inventory_report_per_date",
        text: i18n.t("pages.client.custom.lamcom.reports.reportList.quote_summary_per_customer.title"),
        fields: [
          {
            key: "customer_auto_id",
            class: "customer_auto_id",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.quote_summary_per_customer.fields.customer_auto_id"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: v => v || "-"
          },
          {
            key: "customer_name",
            class: "customer_name",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.quote_summary_per_customer.fields.customer_name"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: v => v || "-"
          },
          {
            key: "quotes_created",
            class: "quotes_created",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.quote_summary_per_customer.fields.quotes_created"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: v => v || "-"
          },
          {
            key: "quotes_won_price",
            class: "quotes_won_price",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.quote_summary_per_customer.fields.quotes_won_price"),
            sortable: true,
            use_formatter_in_excel: true,
            formatter: (value, _, item) => {
              return helperMixin.methods._intlCurrencyFormat(value, item?.currency || "CAD");
            }
          },
          {
            key: "quotes_won",
            class: "quotes_won",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.quote_summary_per_customer.fields.quotes_won"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: (value, _, item) => {
              return helperMixin.methods._intlNumberFormat(value);
            }
          },
          {
            key: "quotes_won_percentage",
            class: "quotes_won_percentage",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.quote_summary_per_customer.fields.quotes_won_percentage"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: (value, _, item) => {
              return helperMixin.methods._intlPercentFormat(value);
            }
          },
          {
            key: "customer_user_reps",
            class: "customer_user_reps",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.quote_summary_per_customer.fields.customer_user_reps"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: v => v || "-"
          },
          {
            key: "customer_created_at",
            class: "customer_created_at",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.quote_summary_per_customer.fields.customer_created_at"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: (value, _, item) => {
              return helperMixin.methods._intlDateFormat(value);
            }
          },
          {
            key: "gap",
            label: "",
            sortable: false,
            use_formatter_in_excel: false
          },

          // Previous Period
          {
            key: "previous_period_data.quotes_created",
            class: "quotes_created",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.quote_summary_per_customer.fields.quotes_created"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: v => v || "-"
          },
          {
            key: "previous_period_data.quotes_won_price",
            class: "quotes_won_price",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.quote_summary_per_customer.fields.quotes_won_price"),
            sortable: true,
            use_formatter_in_excel: true,
            formatter: (value, _, item) => {
              return helperMixin.methods._intlCurrencyFormat(value, item?.currency || "CAD");
            }
          },
          {
            key: "previous_period_data.quotes_won",
            class: "quotes_won",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.quote_summary_per_customer.fields.quotes_won"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: (value, _, item) => {
              return helperMixin.methods._intlNumberFormat(value);
            }
          },
          {
            key: "previous_period_data.quotes_won_percentage",
            class: "quotes_won_percentage",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.quote_summary_per_customer.fields.quotes_won_percentage"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: (value, _, item) => {
              return helperMixin.methods._intlPercentFormat(value);
            }
          },

          {
            key: "gap2",
            label: "",
            sortable: false,
            use_formatter_in_excel: false
          },


          // Last Year Same time
          {
            key: "last_year_period_data.quotes_created",
            class: "quotes_created",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.quote_summary_per_customer.fields.quotes_created"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: v => v || "-"
          },
          {
            key: "last_year_period_data.quotes_won_price",
            class: "quotes_won_price",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.quote_summary_per_customer.fields.quotes_won_price"),
            sortable: true,
            use_formatter_in_excel: true,
            formatter: (value, _, item) => {
              return helperMixin.methods._intlCurrencyFormat(value, item?.currency || "CAD");
            }
          },
          {
            key: "last_year_period_data.quotes_won",
            class: "quotes_won",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.quote_summary_per_customer.fields.quotes_won"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: (value, _, item) => {
              return helperMixin.methods._intlNumberFormat(value);
            }
          },
          {
            key: "last_year_period_data.quotes_won_percentage",
            class: "quotes_won_percentage",
            label: i18n.t("pages.client.custom.lamcom.reports.reportList.quote_summary_per_customer.fields.quotes_won_percentage"),
            sortable: true,
            use_formatter_in_excel: false,
            formatter: (value, _, item) => {
              return helperMixin.methods._intlPercentFormat(value);
            }
          }
        ],
        params: [
          { name: "date", date_from: true, date_to: true, required: true }
        ]
      }
    ],
    projectReportTypeList: [
      {
        name: "pending_projects_with_invoices",
        text: i18n.t(
          "pages.client.custom.lamcom.reports.reportList.pending_projects_with_invoices.projectReportTypeList.pending_projects_with_invoices"
        )
      },
      {
        name: "projects_with_all_completed_wo",
        text: i18n.t(
          "pages.client.custom.lamcom.reports.reportList.pending_projects_with_invoices.projectReportTypeList.projects_with_all_completed_wo"
        )
      }
    ]
  },
  mutations: {},
  actions: {},
  getters: {
    reportList: state => {
      return state.reportList.sort((a, b) => ("" + a.name).localeCompare(b.name));
    },
    // shippingTypeList: "hive_projects/shippingTypeList",
    // shippingServiceList: "hive_projects/shippingServiceList",
    projectReportTypeList: state => state.projectReportTypeList
  }
};
